import React, {useEffect, useRef, useState} from 'react';
import './HomePage.scss';
import { Prompt, useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { bookingActions, bookingSelector, initialState } from '../../../features/bookingSlice';
import { appDataActions, appDataSelector } from '../../../features/appDataSlice';
import ShipmentTypeCard from './shipment-type-card/ShipmentTypeCard';
import {
  AMIAlert,
  AMIButton,
  AMICheckbox,
  AMIDatePicker,
  AMIFormElement,
  AMIInput,
  AMIPopover,
  AMISelect,
  Icon
} from '../../../ui-components/UiComponents';
import { isLessThan10Characters, isValidPostalCode } from '../../../utilities/ValidationUtilities';
import { customerActions, customerDetailsSelector } from '../../../features/customerSlice';
import {
  checkForChannelIslands,
  checkIfTotalValueIsRequired,
  handleAddressSearch,
  handleCountries,
  onCheck,
  onDestinationChange,
  onDestinationPostalCodeChange,
  onOriginChange,
  onOriginCountyChange,
  onOriginPostalCodeChange,
  onReadyDateChange,
  onTradelaneSwitch,
  retrieveQuotes,
  setImperialMetricInBooking,
  storeQuotes,
  trimCityAndPostalCodes,
  updateAncillaryCountryInformation,
  updateForEnvelope
} from './utils';
import { decodeJSONImage, generateImageUrl, getAndSortAddresses, setDefaultEmailAddress } from '../../../utilities/HelperUtilities';
import { Banner, retrieveBanners } from '../../../utilities/ContentRetrieverUtilities';
import { manifestActions } from '../../../features/manifestSlice';
import Banners from './banners/Banners';
import ReactGA from 'react-ga';
import { convertTemplateToAppState } from '../../../utilities/TemplateHandler';
import { getTemplateData } from '../../../utilities/APIUtilities';

const HomePage = () => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const countries = appData.countries;
  const customer = useAppSelector(customerDetailsSelector);
  const templates = customer.templates;
  const showErrors = appData.showHomePageErrors;
  const apiConfig = appData.apiConfig;
  const history = useHistory();
  const originCityRef = useRef<any>(null);
  const destinationCityRef = useRef<any>(null);
  const nebeApiUrl = `${process.env.REACT_APP_NEBE_API_URL}`;
  const isProduction = nebeApiUrl.includes('production');
  const isDevelopment = nebeApiUrl.includes('development');
  const isWlDomainOrigin = appData.isWl;

  const [isSearching, setIsSearching] = useState(false);
  const [quoteRetrieverError, setQuoteRetrieverError] = useState('');
  const [noQuotesText, setNoQuotesText] = useState('');
  const [currencyError, setCurrencyError] = useState(false);
  const [templatesVisible, setTemplatesVisible] = useState(false);
  const [filteredOriginCountries, setFilteredOriginCountries] = useState<any>([]);
  const [banners, setBanners] = useState<undefined | Banner[]>();
  const [isCountriesSpinning, setIsCountriesSpinning] = useState(false);
  const [originPostalCodes, setOriginPostalCodes] = useState([]);
  const [destinationPostalCodes, setDestinationPostalCodes] = useState([]);
  const [currentTimeout, setCurrentTimeout] = useState<any>();
  const [bannerImage, setBannerImage] = useState<any>();
  const [isCountyPopoverVisible, setIsCountyPopoverVisible] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isDevelopment) {
      const TRACKING_ID = `${process.env.REACT_APP_GA_ID}`;
      ReactGA.initialize(TRACKING_ID);
    }
    dispatch(manifestActions.resetManifestState());
    if (appData.countries.length < 1) handleCountries(apiConfig, customer, dispatch);
    fetchTemplates();
  }, [])

  useEffect(() => {
    setDefaultEmailAddress(customer, dispatch, appData.activeTemplate);

    retrieveBanners('announcement-banner-home-page', customer, apiConfig)
    .then((result: any) => {
      setBanners(result)
    })
    .catch((error: any) => console.error(error));
  }, [customer])

  useEffect(() => {
    setFilteredOriginCountries(countries.filter(country => country.value !== 'BR' && country.value !== 'FO'));
    getAndSortAddresses(customer, dispatch, appData.countries, apiConfig);
  }, [countries])

  useEffect(() => {
    if (booking.origin.value) {
      updateAncillaryCountryInformation(booking.origin, dispatch, setIsCountriesSpinning, apiConfig, 'ORIGIN');
    }
  }, [booking.origin.value])

  useEffect(() => {
    if (booking.destination.value) {
      updateAncillaryCountryInformation(booking.destination, dispatch, setIsCountriesSpinning, apiConfig, 'DESTINATION');
    }
  }, [booking.destination.value])

  useEffect(() => {
    if (!appData.isImperialMetricOverwritten && customer.countryOfResidence) {
      setImperialMetricInBooking(customer.countryOfResidence.value, dispatch);
    }
  }, [customer.countryOfResidence]);

  useEffect(() => {
    getAndSetBannerImage();
  }, [appData.previewMode, appData.temporaryBanner.url, appData.wlBannerImage])

  const fetchTemplates = async() => {
    const templates = await getTemplateData(appData.apiConfig);

    if (templates && templates.length > 0) {
      dispatch(customerActions.setTemplates(templates));

      if (
        !appData.activeTemplate?.data.origin.value &&
        !appData.activeTemplate?.data.destination.value &&
        !booking.origin.value &&
        !booking.destination.value
      ) {
        for (const template of templates) {
          if (template.isDefault) {
            setTemplate(template);
            return;
          }
        }
      }
    }
  }

  const onSearchClick = async() => {
    setNoQuotesText('');
    setQuoteRetrieverError('');
    dispatch(appDataActions.setShowHomePageErrors(true));
    storeQuotes([], dispatch);

    for (const error in errHandler) {
      if (!(errHandler as any)[error].criteria) {
        dispatch(appDataActions.setHomePageErrors(true));
        return;
      }
    }

    dispatch(appDataActions.setHomePageErrors(false));

    if (!appData.homePagePieceErrors) {
      setIsSearching(true);
      const updatedBooking = trimCityAndPostalCodes(booking);
      dispatch(bookingActions.updateFromObject(updatedBooking));
      updateForEnvelope(booking, customer, dispatch);
      const searchResult = await retrieveQuotes(updatedBooking, dispatch, bookingActions, customer, setCurrencyError, appData, booking.shipperDetails.countyStateProvince);
      if (searchResult === 'Sorry, there are no quotes that match your entered criteria') {
        setNoQuotesText(searchResult);
        setIsSearching(false);
        return;
      }

      if (typeof (searchResult) === 'string') {
        setQuoteRetrieverError(searchResult);
        setIsSearching(false);
        return;
      }

      storeQuotes(searchResult, dispatch);

      setIsSearching(false);

      if (searchResult) {
        dispatch(appDataActions.setShowHomePageErrors(false));
        await dispatch(appDataActions.setStepsAfterQuote());
        history.push('/quote');
      }
    }
  }

  const errHandler = {
    origin: {
      criteria: booking.origin.value,
      message: 'An origin country must be selected'
    },
    originCityTown: {
      criteria: booking.originCityTown,
      message: 'An origin city/town must be selected'
    },
    originPostalCode: {
      criteria:
        appData.originPostalCodeRegex
          ? booking.originPostalCode
            && isValidPostalCode(booking.originPostalCode, appData.originPostalCodeRegex)
          : isLessThan10Characters(booking.originPostalCode),
      message: appData.originPostalCodeRegex
        ? 'Required. Must be a valid Postal/Zip Code and max 10 characters'
        : 'Must be a valid Postal/Zip Code and max 10 characters'
    },
    destination: {
      criteria: booking.destination.value,
      message: 'A destination country must be selected'
    },
    destinationCityTown: {
      criteria: booking.destinationCityTown,
      message: 'An destination city/town must be selected'
    },
    destinationPostalCode: {
      criteria:
        appData.destinationPostalCodeRegex
          ? booking.destinationPostalCode
            && isValidPostalCode(booking.destinationPostalCode, appData.destinationPostalCodeRegex)
          : isLessThan10Characters(booking.destinationPostalCode),
          message: appData.destinationPostalCodeRegex
            ? 'Required. Must be a valid Postal/Zip Code and max 10 characters'
            : 'Must be a valid Postal/Zip Code and max 10 characters'
    },
    readyDate: {
      criteria: booking.readyDate,
      message: 'This field cannot be empty'
    },
    totalShipmentValue: {
      criteria: checkIfTotalValueIsRequired(customer, booking) ? +booking.totalShipmentValue > 0 : true,
      message: 'Required'
    },
    currency: {
      criteria: booking.preferredCurrency?.value
    }
  }

  const handleAddressAutocomplete = (variant: 'ORIGIN' | 'DESTINATION', element: any) => {
    if (variant === 'ORIGIN') {
      onOriginPostalCodeChange({
        target: {
          value: element.postalCode ? element.postalCode : ''
        }
      }, dispatch);

      if ((element.countryCode === 'US' || element.countryCode === 'CA') && element['ISO3166-2']) {
          onOriginCountyChange({value: element['ISO3166-2']}, dispatch);
      }
      setOriginPostalCodes([]);
    }

    if (variant === 'DESTINATION') {
      onDestinationPostalCodeChange({
        target: {
          value: element.postalCode ? element.postalCode : ''
        }
      }, dispatch);
      setDestinationPostalCodes([]);
    }
  }

  const resetPostalCodes = () => {
    setTimeout(() => {
      setOriginPostalCodes([]);
      setDestinationPostalCodes([]);
    }, 500)
  }

  const onCityTownChange = (event: any, variant: 'ORIGIN' | 'DESTINATION') => {
    handleAddressSearch(
      event,
      variant,
      dispatch,
      booking,
      setCurrentTimeout,
      currentTimeout,
      isProduction,
      originCityRef,
      destinationCityRef,
      setOriginPostalCodes,
      setDestinationPostalCodes
    )
  }

  const getAndSetBannerImage = () => {
    if (appData.previewMode) {
      setBannerImage(appData.temporaryBanner.url)
    } else if (appData.wlBannerImage) {
      const image = decodeJSONImage(appData.wlBannerImage);
      setBannerImage(generateImageUrl(image));
    } else if(!isWlDomainOrigin) {
      setBannerImage('/images/banner.jpg');
    } else {
      setBannerImage('/images/shim.png');
    }
  }

  const setTemplate = (template: any) => {
    dispatch(bookingActions.updateFromObject(convertTemplateToAppState(template.data, initialState)));
    dispatch(appDataActions.setActiveTemplate(template));
    setTemplatesVisible(false);
  }

  const onTemplateSelect = (id: string) => {
    const template: any = templates.find(template => {
      return template.id === id;
    })
    setTemplate(template);
    if (template.data.origin.value) onOriginChange({value: template.data.origin}, dispatch, apiConfig, true)
  }

  const onRemoveTemplateClick = () => {
    dispatch(bookingActions.updateFromObject(initialState));
    dispatch(appDataActions.setActiveTemplate(null));
    setTemplatesVisible(false);
  }

  return (
    <div className="home-page default-page-grid">

      <img
        className={bannerImage ? "home-page__banner" : "home-page__banner home-page__banner--empty"}
        src={bannerImage} alt=""
      />

      <div className="home-page__content">
        <form className="home-page__form vertical-card">
          <div className="card-header">Single Booking</div>
          {!appData.activeTemplate && customer.templates.length > 0 && (
            <p
              className="home-page__form__apply-a-template"
              onClick={() => setTemplatesVisible(!templatesVisible)}
            >
              {templatesVisible ? "Hide Templates" : "Apply a Template"}
            </p>
          )}

          {templatesVisible && (
            <div className="home-page__form__template-cont">
              <p>Select a template:</p>
              <div>
                {templates.map(template => {
                  return (
                    <div
                      className="home-page__form__template-cont__entry"
                      key={template.id}
                      onClick={() => onTemplateSelect(template.id)}
                    >
                      {template.name}
                    </div>
                  )
                })}
              </div>
            </div>
          )}

          {appData.activeTemplate && (
            <div className="home-page__form__template-line">
              <AMIAlert variant="info">
               <p><strong>{appData.activeTemplate.name}</strong> template is active</p>
              </AMIAlert>
              <AMIButton onClick={onRemoveTemplateClick}>
                Remove Template
              </AMIButton>
            </div>
          )}

          <Banners banners={banners} customer={customer} />

          <div className="home-page__form__origin">

            <h4>Origin</h4>

            <AMIFormElement
              label="Country"
              errorMessage={
                showErrors
                && !errHandler.origin.criteria
                  ? errHandler.origin.message
                  : ''
              }
            >
              <AMISelect
                name="field-1"
                placeholder="Start here"
                size="large"
                isSearchable
                style={{
                  width: '100%',
                }}
                defaultValue={booking.origin}
                options={filteredOriginCountries}
                isSpinning={countries.length < 1 || isCountriesSpinning}
                isSpinningMessage={
                  countries.length < 1
                    ? 'Fetching countries'
                    : 'Processing'
                }
                onChange={(event) => onOriginChange(event, dispatch, apiConfig)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="City/Town"
              errorMessage={
                showErrors
                && !errHandler.originCityTown.criteria
                ? errHandler.originCityTown.message
                : ''
              }
            >
              <AMIInput
                name="input-2"
                placeholder="Required"
                size="large"
                style={{
                  width: '100%',
                }}
                value={booking.originCityTown}
                onChange={(event) => onCityTownChange(event, 'ORIGIN')}
                refFn={originCityRef}
                onBlur={() => resetPostalCodes()}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Postal/Zip Code"
              errorMessage={
                showErrors
                && !errHandler.originPostalCode.criteria
                  ? errHandler.originPostalCode.message
                  : ''
              }
            >
              <AMIInput
                name="send-from-origin-postal-code"
                placeholder={appData.originPostalCodeRegex
                  ? "Required"
                  : ""}
                size="large"
                style={{
                  width: '100%',
                }}
                value={booking.originPostalCode}
                onChange={(event) => onOriginPostalCodeChange(event, dispatch)}
                onBlur={(event) => checkForChannelIslands(event, 'ORIGIN', booking, dispatch, appData)}
              />
            </AMIFormElement>

            {originPostalCodes?.length > 0 && (
              <div className="home-page__form__origin__results-container">
              {originPostalCodes.map((element: any, index: number) => {
                return (
                  <p
                    key={element.lat + index}
                    onClick={() => handleAddressAutocomplete('ORIGIN', element)}
                  >{element.placeName} - <strong>{element.postalCode}</strong></p>
                )
              })}
            </div>
            )}

            {(booking.origin.value === "US" || booking.origin.value === "CA") && appData.shipperCounties.length > 0 && (
              <AMIFormElement
                label={(
                  <div>State/Province
                    <span
                      onMouseEnter={() => setIsCountyPopoverVisible(true)}
                      onMouseLeave={() => setIsCountyPopoverVisible(false)}
                    >
                      <Icon
                        name="CircleQuestion"
                        color="var(--secondary)"
                        style={{marginLeft: '4px'}}
                      />
                      <AMIPopover isVisible={isCountyPopoverVisible}>
                        If a state is not provided then UPS will not be able to quote for this shipment.
                      </AMIPopover>
                    </span>
                  </div>
                )}
              >
                <AMISelect
                  name="origin-county"
                  size="large"
                  isSearchable
                  style={{
                    width: '100%',
                  }}
                  defaultValue={appData.shipperCounties.find((item: any) => {
                    return item.value === booking.shipperDetails.countyStateProvince
                  })}
                  options={appData.shipperCounties}
                  onChange={(event) => onOriginCountyChange(event, dispatch)}
                />
              </AMIFormElement>
            )}

            <AMICheckbox
              className="home-page__form__origin-residential-check"
              checked={booking.originResidential}
              text="Residential address?"
              onChange={() => onCheck('originResidential', dispatch, booking)}
            />
          </div>

          <div
            className="home-page__form__tradelane-switch"
            onClick={() => onTradelaneSwitch(booking, dispatch, apiConfig)}
          >
            <Icon
              name="Switch"
              color="var(--secondary)"
              style={{
                width: "24px",
                height: "24px"
              }}
            />
            <p>Switch</p>
          </div>

          <div className="home-page__form__destination">

            <h4>Destination</h4>

            <AMIFormElement
              label="Send To Destination"
              errorMessage={
                showErrors
                && !errHandler.destination.criteria
                  ? errHandler.destination.message
                  : ''
              }
            >
              <AMISelect
                name="field-2"
                placeholder="Required"
                isSearchable
                size="large"
                style={{
                  width: '100%',
                }}
                defaultValue={booking.destination}
                options={countries}
                isSpinning={countries.length < 1 || isCountriesSpinning}
                isSpinningMessage={
                  countries.length < 1
                    ? 'Fetching countries'
                    : 'Processing'
                }
                onChange={(event) => onDestinationChange(event, dispatch)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="City/Town"
              errorMessage={
                showErrors
                && !errHandler.destinationCityTown.criteria
                ? errHandler.destinationCityTown.message
                : ''
              }
            >
              <AMIInput
                name="input-5"
                placeholder="Required"
                size="large"
                style={{
                  width: '100%',
                }}
                value={booking.destinationCityTown}
                onChange={(event) => onCityTownChange(event, 'DESTINATION')}
                refFn={destinationCityRef}
                onBlur={() => resetPostalCodes()}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Postal/Zip Code"
              errorMessage={
                showErrors
                && !errHandler.destinationPostalCode.criteria
                  ? errHandler.destinationPostalCode.message
                  : ''
              }
            >
              <AMIInput
                name="send-to-destination-postal-code"
                placeholder={appData.destinationPostalCodeRegex
                  ? "Required"
                  : ""}
                size="large"
                style={{
                  width: '100%',
                }}
                value={booking.destinationPostalCode}
                onChange={(event) => onDestinationPostalCodeChange(event, dispatch)}
                onBlur={(event) => checkForChannelIslands(event, 'DESTINATION', booking, dispatch, appData)}
              />
            </AMIFormElement>

            {destinationPostalCodes?.length > 0 && (
              <div className="home-page__form__destination__results-container">
              {destinationPostalCodes.map((element: any, index: number) => {
                return (
                  <p
                    key={element.lat + index}
                    onClick={() => handleAddressAutocomplete('DESTINATION', element)}
                  >{element.placeName} - <strong>{element.postalCode}</strong></p>
                )
              })}
            </div>
            )}

            <AMICheckbox
              className="home-page__form__destination-residential-check"
              checked={booking.destinationResidential}
              text="Residential address?"
              onChange={() => onCheck('destinationResidential', dispatch, booking)}
            />
          </div>

          <AMIFormElement
            label='Ready Date'
            errorMessage={
              showErrors
              && !errHandler.readyDate.criteria
                ? errHandler.readyDate.message
                : ''
            }
          >
            <AMIDatePicker
              size="large"
              placeholder="Required"
              style={{
                width: '100%'
              }}
              numFutureDays={7}
              datePicked={booking.readyDate}
              onChange={(event) => onReadyDateChange(event, dispatch)}
            />

          </AMIFormElement>

          <ShipmentTypeCard errHandler={errHandler}/>

        </form>

        {appData.showHomePageErrors
          && (appData.homePageErrors
          || appData.homePagePieceErrors)
          && (
            <AMIAlert
              variant="error"
              className="home-page__error-message"
            >
              Please address all highlighted errors
            </AMIAlert>
          )
        }

        {noQuotesText
          && <AMIAlert
                variant='warning'
                style={{
                  marginTop: '24px'
                }}
            >
            {noQuotesText}
            </AMIAlert>}

        {quoteRetrieverError
          && <AMIAlert
                variant='error'
                style={{
                  marginTop: '24px'
                }}
            >
            {quoteRetrieverError}. Please try again or contact customer services directly.
            </AMIAlert>}

        {currencyError
          && <AMIAlert
            variant='error'
            style={{
              marginTop: '24px'
            }}
          >
            Sorry, we could not return an exchange rate for the selected currency, please try again or choose another currency.
          </AMIAlert>
        }

        <AMIButton
          className="home-page__search-button"
          id="search-button"
          size="large"
          variant='primary'
          isSpinning={isSearching}
          onClick={onSearchClick}
        >Search</AMIButton>
      </div>

      <Prompt
        message={(location, action) => {
          let message: boolean | string = true;
          if (action === 'POP' && location.pathname === '/auth') {
            message = 'Are you sure you want to go back? All progress will be lost and you will be signed out.'
          }
          return message;
        }}
      />

    </div>
  )
}

export default HomePage;