import React, { useState } from 'react';
import './WlExportModal.scss';
import { AMIButton, AMIDatePicker, AMIFormElement, AMIInput, AMIModal, Icon } from '../../../../../ui-components/UiComponents';
import { exportCSV, filterBookingsByDateRange, mapBookingsForCSV } from './Utils-WlExportModal';
import { getFullWlBookingsData } from '../../../../../utilities/APIUtilities';
import { useAppSelector } from '../../../../../app/hooks';
import { appDataSelector } from '../../../../../features/appDataSlice';
import dayjs from 'dayjs';

const WlExportModal: React.FC<{
  typedFilter: string;
  close: () => void;
}> = ({
  typedFilter,
  close
}) => {

  const { apiConfig } = useAppSelector(appDataSelector);

  const [exportType, setExportType] = useState<'ALL' | 'FILTERED' | 'RANGE' | 'BOTH'>('ALL');
  const [dateFrom, setDateFrom] = useState<number>();
  const [dateTo, setDateTo] = useState<number>();
  const [isExporting, setIsExporting] = useState(false);

  const onExportClick = async() => {
    setIsExporting(true);
    const response = await getFullWlBookingsData(apiConfig) as any;
    if (!response) {
      window.alert("An error occurred fetching this data, please try again or contact customer services.")
      setIsExporting(false);
      return;
    }

    const fullData = response.data;

    if (exportType === 'ALL') exportCSV(mapBookingsForCSV(fullData));

    if (exportType === 'FILTERED') {
      const filteredBookings = filterBookings(fullData);
      exportCSV(mapBookingsForCSV(filteredBookings));
    }

    if (exportType === 'RANGE') {
      const mappedBookings = mapBookingsForCSV(fullData);
      exportCSV(filterBookingsByDateRange(mappedBookings, dateFrom, dateTo));
    }

    if (exportType === 'BOTH') {
      const filteredBookings = filterBookings(fullData);
      const mappedBookings = mapBookingsForCSV(filteredBookings);
      exportCSV(filterBookingsByDateRange(mappedBookings, dateFrom, dateTo));
    }

    close();
  }

  const filterBookings = (data: any) => {
    return data.filter((booking: any) => {
      return (
        dayjs(booking.shipmentDate).format('DD/MM/YYYY').includes(typedFilter)
        || booking.companyName.toLowerCase().includes(typedFilter.toLowerCase())
        || booking.customerShippingReference?.toLowerCase().includes(typedFilter.toLowerCase())
      )
    })
  }

  return (
    <AMIModal
      title="Export as CSV"
      width="90vw"
      style={{overflow: "visible"}}
      close={close}
      buttons={[<AMIButton
        key={Math.random()}
        variant='default'
        size="large"
        onClick={close}
      >Close
      </AMIButton>,
      <AMIButton
        key={Math.random()}
        variant='primary'
        size="large"
        isSpinning={isExporting}
        onClick={onExportClick}
      >
        <Icon
          name="Download"
          color="white"
        />Export
      </AMIButton>
    ]}
    >
      <div className="wl-export-modal">
        <p>Which bookings do you want to export?</p>

        <div className="wl-export-modal__radio-btns">
          <div>
            <input
              id="ALL"
              type="radio"
              value="ALL"
              defaultChecked
              name="export-group"
              onChange={(event) => setExportType(event.target.value as "ALL")}
            />
            <label htmlFor="ALL">All Bookings</label>
          </div>

          <div>
            <input
              id="FILTERED"
              type="radio"
              value="FILTERED"
              name="export-group"
              onChange={(event) => setExportType(event.target.value as "FILTERED")}
            />
            <label htmlFor="FILTERED">Filtered Bookings</label>
          </div>

          <div>
            <input
              id="RANGE"
              type="radio"
              value="RANGE"
              name="export-group"
              onChange={(event) => setExportType(event.target.value as "RANGE")}
            />
            <label htmlFor="RANGE">Bookings within a Date Range</label>
          </div>

          <div>
            <input
              id="BOTH"
              type="radio"
              value="BOTH"
              name="export-group"
              onChange={(event) => setExportType(event.target.value as "BOTH")}
            />
            <label htmlFor="BOTH">Filtered Bookings & Within a Date Range</label>
          </div>

        </div>

        <div className="wl-export-modal__inputs">
          <AMIFormElement label="Current Typed Filter">
            <AMIInput
              name="current-typed-filter"
              size="large"
              value={exportType === "FILTERED" || exportType === "BOTH" ? typedFilter : ""}
              disabled
            />
          </AMIFormElement>

          <AMIFormElement label="Date From">
            <AMIDatePicker
              size="large"
              allowPastDates
              allowWeekends
              placeholder="1 January 2000"
              disabled={exportType === "ALL" || exportType === "FILTERED"}
              onChange={(event) => setDateFrom(event)}
            />
          </AMIFormElement>

          <AMIFormElement label="Date To">
            <AMIDatePicker
              size="large"
              allowPastDates
              allowWeekends
              placeholder="Today"
              disabled={exportType === "ALL" || exportType === "FILTERED"}
              onChange={(event) => setDateTo(event)}
            />
          </AMIFormElement>
        </div>

        <>
          <Icon
            name="CircleExclamation"
            style={{transform: "translateY(3px)", marginRight: "4px"}}
          />
          Ad blockers may interfere with downloading the CSV file. If you are having issues please disable your ad blocker and try again.
        </>
      </div>
    </AMIModal>
  )
}

export default WlExportModal;