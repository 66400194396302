import dayjs from 'dayjs';
import { WlBookingHistoryItemFull } from '../WlBookingsPage-Utils';
import { generateStreetLinesArray } from '../../../../../utilities/HelperUtilities';

export function exportCSV(bookings: any) {
  // ONCE THE ORDER IS FINALIZED WE COULD MANUALLY ADD COLUMN TITLES LIKE THIS TO HAVE BETTER FORMATTING
  // let rows = [['Booking Date',	'Your Ref',	'AMI Ref', 'Carrier Ref', 'Company',	'Carrier',	'AMI Cost',	'Percentage Increase',	'Increased Amount',	'Shipper Price']];

  let rows = [Object.keys(bookings[0])];
  for (const row of bookings) {
    rows.push(Object.values(row));
  }

  const csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
  const encodedUri = encodeURI(csvContent);
  window.open(encodedUri);
}

export function mapBookingsForCSV(bookings: WlBookingHistoryItemFull[]) {
  return bookings.map((el: WlBookingHistoryItemFull) => {
    const customs = el.whiteLabelCustomsDetails;
    return ({
      company: el.companyName?.replace(/,/g, ''),
      carrierRef: el.carrierShippingReference?.replace(/,/g, ''),
      amiCost: el.directCustomerCost?.value,
      percentageIncrease: el.markupApplied.percentage ? el.markupApplied.markup : 'n/a',
      increasedAmount: el.markup?.value,
      shipperPrice: el.indirectCustomerCost?.value,
      carrier: el.carrierName?.replace(/,/g, ''),
      amiRef: el.amiReference?.replace(/,/g, ''),
      yourRef: el.customerShippingReference?.replace(/,/g, ''),
      logonEmail: el.logonEmail?.replace(/,/g, ''),
      bookingConfirmationEmail: el.bookingConfirmationEmail?.replace(/,/g, ''),
      bookingDate: el.shipmentDate ? dayjs(el.shipmentDate * 1000).format('YYYY/MM/DD') : '',
      shipmentType: el.shipmentType?.replace(/,/g, ''),
      contentDescription: el.contentDescription?.replace(/,/g, ''),
      serviceType: el.serviceType?.replace(/,/g, ''),
      senderName: el.senderDetails?.contactDetails.name?.replace(/,/g, ''),
      senderOrganisation: el.senderDetails?.address.organisation?.replace(/,/g, ''),
      senderStreetLines: el.senderDetails? generateStreetLinesArray.apply(null, el.senderDetails.address.streetLines) : 'N/A',
      senderCity: el.senderDetails?.address.city?.replace(/,/g, ''),
      senderStateOrCounty: el.senderDetails?.address.stateOrCounty?.replace(/,/g, ''),
      senderPostalCode: el.senderDetails?.address.postalCode?.replace(/,/g, ''),
      senderCountryCode: el.senderDetails?.address.countryCode?.replace(/,/g, ''),
      senderResidential: el.senderDetails?.address.residential,
      senderTelephone: el.senderDetails?.contactDetails.telephone?.replace(/,/g, ''),
      senderEmail: el.senderDetails?.notificationDetails.email?.replace(/,/g, ''),
      senderMobile: el.senderDetails?.notificationDetails.mobile?.replace(/,/g, ''),
      collectionName: el.collectionDetails?.contactDetails.name?.replace(/,/g, ''),
      collectionOrganisation: el.collectionDetails?.address.organisation?.replace(/,/g, ''),
      collectionStreetLines: el.collectionDetails? generateStreetLinesArray.apply(null, el.collectionDetails.address.streetLines) : 'N/A',
      collectionCity: el.collectionDetails?.address.city?.replace(/,/g, ''),
      collectionStateOrCounty: el.collectionDetails?.address.stateOrCounty?.replace(/,/g, ''),
      collectionPostalCode: el.collectionDetails?.address.postalCode?.replace(/,/g, ''),
      collectionCountryCode: el.collectionDetails?.address.countryCode?.replace(/,/g, ''),
      collectionResidential: el.collectionDetails?.address.residential,
      collectionTelephone: el.collectionDetails?.contactDetails.telephone?.replace(/,/g, ''),
      collectionEmail: el.collectionDetails?.notificationDetails.email?.replace(/,/g, ''),
      collectionMobile: el.collectionDetails?.notificationDetails.mobile?.replace(/,/g, ''),
      deliveryName: el.deliveryDetails?.contactDetails.name?.replace(/,/g, ''),
      deliveryOrganisation: el.deliveryDetails?.address.organisation?.replace(/,/g, ''),
      deliveryStreetLines: el.deliveryDetails? generateStreetLinesArray.apply(null, el.deliveryDetails.address.streetLines) : 'N/A',
      deliveryCity: el.deliveryDetails?.address.city?.replace(/,/g, ''),
      deliveryStateOrCounty: el.deliveryDetails?.address.stateOrCounty?.replace(/,/g, ''),
      deliveryPostalCode: el.deliveryDetails?.address.postalCode?.replace(/,/g, ''),
      deliveryCountryCode: el.deliveryDetails?.address.countryCode?.replace(/,/g, ''),
      deliveryResidential: el.deliveryDetails?.address.residential,
      deliveryTelephone: el.deliveryDetails?.contactDetails.telephone?.replace(/,/g, ''),
      deliveryEmail: el.deliveryDetails?.notificationDetails.email?.replace(/,/g, ''),
      deliveryMobile: el.deliveryDetails?.notificationDetails.mobile?.replace(/,/g, ''),
      parcels: createParcelsArray(el.parcels),
      shipmentCurrency: customs?.amount?.currency.replace(/,/g, ''),
      shipmentValue: customs?.amount?.value,
      commodities: customs?.commodities ? createCommoditiesArray(customs?.commodities) : 'N/A',
      documentFileType: customs?.electronicTradeDetails?.uploadedDocuments?.contentType,
      documentReference: customs?.electronicTradeDetails?.uploadedDocuments?.invoiceReference?.replace(/,/g, ''),
      exportComplianceStatement: customs?.exportComplianceStatement?.replace(/,/g, ''),
      b13AFilingOption: customs?.b13AFilingOption?.replace(/,/g, ''),
      termsOfSale: customs?.termsOfSale?.replace(/,/g, ''),
      invoiceNumber: customs?.invoiceDetails?.invoiceNumber?.replace(/,/g, ''),
      invoiceDate: customs?.invoiceDetails?.invoiceDate,
      declaredChargesCurrency: customs?.invoiceDetails?.declaredCharges?.currency?.replace(/,/g, ''),
      declaredChargesValue: customs?.invoiceDetails?.declaredCharges?.value,
      declaredChargesType: customs?.invoiceDetails?.declaredCharges?.type?.replace(/,/g, ''),
      receiverVatNumber: customs?.invoiceDetails?.receiverVatNumber?.replace(/,/g, ''),
      shipperVatNumber: customs?.invoiceDetails?.shipperVatNumber?.replace(/,/g, ''),
      taxDetails: customs?.taxDetails ? createTaxDetailsArray(customs.taxDetails) : 'N/A',
      importerName: customs?.importerOfRecordDetails?.contactDetails.name?.replace(/,/g, ''),
      importerOrganisation: customs?.importerOfRecordDetails?.address.organisation?.replace(/,/g, ''),
      importerStreetLines: customs?.importerOfRecordDetails? generateStreetLinesArray.apply(null, customs.importerOfRecordDetails?.address.streetLines) : 'N/A',
      importerCity: customs?.importerOfRecordDetails?.address.city?.replace(/,/g, ''),
      importerStateOrCounty: customs?.importerOfRecordDetails?.address.stateOrCounty?.replace(/,/g, ''),
      importerPostalCode: customs?.importerOfRecordDetails?.address.postalCode?.replace(/,/g, ''),
      importerCountryCode: customs?.importerOfRecordDetails?.address.countryCode?.replace(/,/g, ''),
      importerResidential: customs?.importerOfRecordDetails?.address.residential,
      importerTelephone: customs?.importerOfRecordDetails?.contactDetails.telephone?.replace(/,/g, ''),
      brokerName: el.brokerDetails?.contactDetails.name?.replace(/,/g, ''),
      brokerOrganisation: el.brokerDetails?.address.organisation?.replace(/,/g, ''),
      brokerStreetLines: el.brokerDetails? generateStreetLinesArray.apply(null, el.brokerDetails.address.streetLines) : 'N/A',
      brokerCity: el.brokerDetails?.address.city?.replace(/,/g, ''),
      brokerStateOrCounty: el.brokerDetails?.address.stateOrCounty?.replace(/,/g, ''),
      brokerPostalCode: el.brokerDetails?.address.postalCode?.replace(/,/g, ''),
      brokerCountryCode: el.brokerDetails?.address.countryCode?.replace(/,/g, ''),
      brokerResidential: el.brokerDetails?.address.residential,
      brokerTelephone: el.brokerDetails?.contactDetails.telephone?.replace(/,/g, ''),
      brokerEmail: el.brokerDetails?.notificationDetails.email?.replace(/,/g, ''),
      brokerMobile: el.brokerDetails?.notificationDetails.mobile?.replace(/,/g, ''),
      pickupConfirmationCode: el.pickupConfirmationCode?.replace(/,/g, ''),
      indirectCustomerInvoiceRequiredCharges: el.indirectCustomerInvoice?.charges?.required ? createChargesArray(el.indirectCustomerInvoice?.charges?.required) : 'N/A',
      indirectCustomerInvoiceOptionalCharges: el.indirectCustomerInvoice?.charges?.optional ? createChargesArray(el.indirectCustomerInvoice?.charges?.optional) : 'N/A',
      indirectCustomerInvoiceFreightCurrency: el.indirectCustomerInvoice?.rate.currency?.replace(/,/g, ''),
      indirectCustomerInvoiceFreightValue: el.indirectCustomerInvoice?.rate.value,
      indirectCustomerInvoiceTotalCurrency: el.indirectCustomerInvoice?.cost.currency?.replace(/,/g, ''),
      indirectCustomerInvoiceTotalValue: el.indirectCustomerInvoice?.cost.value,
    }) as any;
  })
}

function createParcelsArray(parcels: any) {
  const mapped = parcels.map((el: any, index: number) => {
    return {
      [`parcel ${index + 1}`]: {
        weight: el.weight.value,
        weightUnit: el.weight.unit,
        dimensionUnit: el.dimensions.unit,
        length: el.dimensions.length,
        width: el.dimensions.width,
        height: el.dimensions.height,
      }
    }
  })

  // IF WE DON'T REMOVE THE COMMAS BELOW THEN THE CSV PUTS EACH PART OF THE OBJECT IN A NEW COLUMN WHICH WE DON'T WANT
  return JSON.stringify(mapped).replace(/,/g, ' ');
}

function createCommoditiesArray(commodities: any) {
  const mapped = commodities.map((el: any, index: number) => {
    return {
      [`commodity ${index + 1}`]: {
        code: el.commodityCode?.replace(/,/g, ''),
        description: el.description?.replace(/,/g, ''),
        quantity: el.quantity?.value,
        unitCurrency: el.unitPrice.currency,
        unitPrice: el.unitPrice.value,
        weightUnit: el.weight.unit,
        weightValue: el.weight.value
      }
    }
  })

  // IF WE DON'T REMOVE THE COMMAS BELOW THEN THE CSV PUTS EACH PART OF THE OBJECT IN A NEW COLUMN WHICH WE DON'T WANT
  return JSON.stringify(mapped).replace(/,/g, ' ');
}

function createTaxDetailsArray(details: any) {
  const mapped = details.map((el: any, index: number) => {
    return {
      [`Tax Detail ${index + 1}`]: {
        party: el.party,
        value: el.value
      }
    }
  })

  // IF WE DON'T REMOVE THE COMMAS BELOW THEN THE CSV PUTS EACH PART OF THE OBJECT IN A NEW COLUMN WHICH WE DON'T WANT
  return JSON.stringify(mapped).replace(/,/g, ' ');
}

function createChargesArray(charges: any) {
  const mapped = charges.map((el: any, index: number) => {
    return {
      [`Charge ${index + 1}`]: {
        currency: el.currency,
        type: el.type,
        value: el.value
      }
    }
  })

  // IF WE DON'T REMOVE THE COMMAS BELOW THEN THE CSV PUTS EACH PART OF THE OBJECT IN A NEW COLUMN WHICH WE DON'T WANT
  return JSON.stringify(mapped).replace(/,/g, ' ');
}

export function filterBookingsByDateRange(bookings: any, dateFrom: number | undefined, dateTo: number | undefined) {
  return bookings.filter((el: any) => {
    const from = dateFrom ? dayjs(dateFrom) : dayjs('2000-01-01');
    const to = dateTo ? dayjs(dateTo) : dayjs();
    return (
      (dayjs(el.bookingDate).isAfter(from) || dayjs(el.bookingDate).isSame(from))
      && (dayjs(el.bookingDate).isBefore(to) || dayjs(el.bookingDate).isSame(to))
    )
  })
}