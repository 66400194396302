import React, { useEffect, useState } from 'react';
import './CustomerPageLogins.scss';
import { AMIButton, AMIFormElement, AMIInput, AMIModal, AMISpinner, Icon } from '../../../../../../ui-components/UiComponents';
import { createWlUser, getWlUsersByEntityId } from '../../../../../../utilities/APIUtilities';
import { useAppSelector } from '../../../../../../app/hooks';
import { appDataSelector } from '../../../../../../features/appDataSlice';
import UserLoginLine from './user-login-line/UserLoginLine';

const CustomerPageLogins: React.FC<{
  entity: any;
}> = ({
  entity
}) => {

  const { apiConfig } = useAppSelector(appDataSelector);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [logins, setLogins] = useState<any>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [user, setUser] = useState({
    name: '',
    email: ''
  })

  useEffect(() => {
    getAndSetLogins();
  }, [])

  const getAndSetLogins = async() => {
    setIsFetching(true);
    const response: any = await getWlUsersByEntityId(entity.id, apiConfig);
    if (response) {
      setLogins(response.data);
    }
    setIsFetching(false);
  }

  const onFieldChange = (field: string, value: string) => {
    setUser((prevState) => {
      return {
        ...prevState,
        [field]: value
      }
    })
  }

  const onSaveClick = async() => {
    setIsSaving(true);
    const body = {
      entityId: entity.id,
      email: user.email,
      contactDetails: {
          name: user.name
      }
    }

    const response = await createWlUser(body, apiConfig);
    if (!response) window.alert("An error occurred. Please try again or contact customer service.");
    setIsSaving(false);
    if (response) {
      setShowAddUserModal(false);
      getAndSetLogins();
    }
  }

  return (
    <>
      <div className="customer-page-logins">

        {!isFetching && (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                {/* <th>Enabled</th> REQUIRED FOR DAY 2 WL WHEN USERS ARE ENABLE/DISABLED */}
              </tr>
            </thead>
            {logins.map((login: any) => {
              return (
                <UserLoginLine login={login} key={login.id} getAndSetLogins={getAndSetLogins}/>
              )
            })}
          </table>
        )}

        {isFetching && (
          <div className="wl-bookings__spinner">
            <AMISpinner />
            <p>Fetching logins...</p>
          </div>
        )}

        <AMIButton
          variant="secondary"
          size="large"
          style={{marginTop: "24px"}}
          onClick={() => setShowAddUserModal(true)}
        >
          <Icon
            name="User"
            color="var(--textLight)"
          />
          Add User
        </AMIButton>
      </div>

      {showAddUserModal && (
        <AMIModal
          title="Add User"
          width="90vw"
          buttons={[
            <AMIButton
              size="large"
              key={Math.random() * 100}
              disabled={isSaving}
              onClick={() => setShowAddUserModal(false)}
            >Close</AMIButton>,
            <AMIButton
              size="large"
              variant="primary"
              isSpinning={isSaving}
              key={Math.random() * 100}
              onClick={onSaveClick}
            >Save</AMIButton>
          ]}
          close={() => setShowAddUserModal(false)}
        >
          <form className="customer-logins-add-user-modal">
            <AMIFormElement
              label="Name"
            >
              <AMIInput
                name="name"
                value={user.name}
                size="large"
                onChange={(event) => onFieldChange("name", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Email"
            >
              <AMIInput
                name="email"
                value={user.email}
                size="large"
                onChange={(event) => onFieldChange("email", event.target.value)}
              />
            </AMIFormElement>

          </form>
        </AMIModal>
      )}
    </>
  )
}

export default CustomerPageLogins;