import React, { useState } from 'react';
import { AMIButton, AMIFormElement, AMIInput, AMIModal, Icon } from '../../../../../../../ui-components/UiComponents';
import { useAppSelector } from '../../../../../../../app/hooks';
import { appDataSelector } from '../../../../../../../features/appDataSlice';
import { updateWlUser } from '../../../../../../../utilities/APIUtilities';

const UserLoginLine: React.FC<{login: any; getAndSetLogins: any}> = ({login, getAndSetLogins}) => {

  const { apiConfig } = useAppSelector(appDataSelector);

  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(login.contactDetails.name);

  const onSaveClick = async() => {
    setIsSaving(true);
    const body = {
      contactDetails: {
          name
      }
    }

    const response = await updateWlUser(body, apiConfig, login.id);
    if (!response) window.alert("An error occurred. Please try again or contact customer service.");
    setIsSaving(false);
    if (response) {
      setShowModal(false);
      getAndSetLogins();
    }
  }

  return (
    <>
      <tr>
        <td>{login.contactDetails.name}</td>
        <td>{login.email}</td>
        {/* <td>
          REQUIRED FOR DAY 2 WL WHEN USERS ARE ENABLE/DISABLED
          <AMISwitch
            titles={[]}
            position={login.enabled}
            onToggle={() => null}
          />
        </td> */}
        <td>
          <Icon
            name="Edit"
            style={{cursor: "pointer"}}
            onClick={() => setShowModal(true)}
          />
        </td>
      </tr>

      {showModal && (
        <AMIModal
          title={`Edit ${login.email}`}
          width="90vw"
          buttons={[
            <AMIButton
              size="large"
              key={Math.random() * 100}
              disabled={isSaving}
              onClick={() => setShowModal(false)}
            >Close</AMIButton>,
            <AMIButton
              size="large"
              variant="primary"
              isSpinning={isSaving}
              key={Math.random() * 100}
              onClick={onSaveClick}
            >Save</AMIButton>
          ]}
          close={() => setShowModal(false)}
          >
          <form className="customer-logins-add-user-modal">
            <AMIFormElement
              label="Name"
            >
              <AMIInput
                name="name"
                value={name}
                size="large"
                onChange={(event) => setName(event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Email"
            >
              <AMIInput
                name="email"
                value={login.email}
                size="large"
                disabled
                onChange={() => null}
              />
            </AMIFormElement>

          </form>
        </AMIModal>
      )}
    </>
  )
}

export default UserLoginLine;