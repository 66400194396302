import {
  Booking,
  bookingActions, BrokerDetails, CollectionDetails, ConsigneeDetails,
  ElectronicTradeDocumentType,
  ImperialMetric,
  ShipmentType,
  ShipperDetails,
  SpecialServiceType,
  TermsOfSale
} from "../../../features/bookingSlice";
import { CustomerDetails } from "../../../features/customerSlice";
import {bookingIGCall, BookingRequestBody, logError} from "../../../utilities/APIUtilities";
import { camelCaseToSentence, generateLabel, generateStreetLinesArray, getCarrierName, removePeriodsFromString, trimIfString } from "../../../utilities/HelperUtilities";
import dayjs from "dayjs";
import {
  getRule,
  getTradeRoute,
  isIntraEu,
  TradeRoute
} from "../../../utilities/RulesEngineUtilities";
import { ApiConfig, AppData, AttachOrUpload, OwnOrGenerate } from "../../../features/appDataSlice";
import { getInvoiceNumber } from "../details-page/utilities";
import { DetailSection } from "../../../components/saved-address-select/SavedAddressSelect";

export const KG_TO_LB_MULTIPLICATION = 2.20462262185;

export interface Commodity {
  commodityCode: string | null,
  description: string | null,
  countryOfOrigin: string,
  quantity: {
    value: string,
    unit: string
  } | null,
  unitPrice: {
    value: string,
    currency: string
  } | null,
  weight: {
    value: string,
    unit: string
  } | null
}

const removeHiddenFieldData = (address: any, hiddenFields: any, section: DetailSection) => {
  const addressCopy = {...address};
  if (hiddenFields[section]) {
    for (const field of hiddenFields[section]) {
      addressCopy[field] = '';
    }
  }
  return addressCopy;
}

export const placeBooking = async(
  booking: Booking,
  appData: AppData,
  customer: CustomerDetails,
  dispatch: any,
  hiddenFields: any
) => {
  const apiConfig = appData.apiConfig;
  let shipmentType = booking.shipmentType;
  const carrier = getCarrierName(booking.selectedQuote.quoteId);

  const confirmShipmentType = async () => {
    let totalShipmentWeight = 0;
    for (const piece of booking.pieces) {
      totalShipmentWeight += +piece.weight;
    }

    const isImperial = booking.imperialMetric === ImperialMetric.IMPERIAL;
    const weight = isImperial ? totalShipmentWeight / KG_TO_LB_MULTIPLICATION : totalShipmentWeight;
    const polandDocsException = await getRule(appData.customRules, 'polandDocsException');

    if (weight > 2.5 && !polandDocsException) {
      shipmentType = ShipmentType.NON_DOCS;
    }
  }

  await confirmShipmentType();

  const generateShipmentDateTime = (readyDate: number) => {
    let shipmentDateTime;
    const pickUpFrom = collectionDetails?.pickUpFrom;
    if (booking.selectedQuote.serviceType === 'COLLECTED') {
      shipmentDateTime = dayjs(readyDate).format('YYYY-MM-DD') + 'T' +  pickUpFrom + `${pickUpFrom?.length > 5 ? '' : ':00'}Z`;
    } else {
      shipmentDateTime = dayjs(readyDate).format('YYYY-MM-DD') + 'T' + '23:59:00Z';
    }

    return shipmentDateTime;
  }

  const quote = booking.selectedQuote;
  const collectionDetails: CollectionDetails = removeHiddenFieldData(booking.collectionDetails, hiddenFields, DetailSection.COLLECTION_DETAILS);
  const senderDetails: ShipperDetails = removeHiddenFieldData(booking.shipperDetails, hiddenFields, DetailSection.SHIPPER_DETAILS);
  const deliveryDetails: ConsigneeDetails = removeHiddenFieldData(booking.consigneeDetails, hiddenFields, DetailSection.CONSIGNEE_DETAILS);
  const brokerDetails: BrokerDetails = removeHiddenFieldData(booking.brokerDetails, hiddenFields, DetailSection.BROKER_DETAILS);

  const getImporterOfRecordDetails = () => {
    let details = {
      deliveryAddress: true,
      contactDetails: {
        name: trimIfString(deliveryDetails.contactName),
        telephone: trimIfString(deliveryDetails.telephoneNumber)
      },
      address: {
        organisation: trimIfString(deliveryDetails.companyName),
        countryCode: trimIfString(booking.destination.value),
        postalCode: trimIfString(booking.destinationPostalCode),
        streetLines: generateStreetLinesArray(
          deliveryDetails.addressLine1,
          deliveryDetails.addressLine2,
          deliveryDetails.addressLine3
        ),
        city: trimIfString(deliveryDetails.cityTown),
        stateOrCounty: deliveryDetails.countyStateProvince ? trimIfString(deliveryDetails.countyStateProvince) : null,
        residential: booking.destinationResidential
      },
      notificationDetails: {
        email: trimIfString(deliveryDetails.email),
        mobile: trimIfString(deliveryDetails.telephoneNumber)
      }
    }

    if (booking.brokerDetails.brokerSelected) {
      details = {
        deliveryAddress: false,
        contactDetails: {
          name: trimIfString(brokerDetails.contactName),
          telephone: trimIfString(brokerDetails.telephoneNumber)
        },
        address: {
          organisation: trimIfString(brokerDetails.companyName),
          countryCode: trimIfString(brokerDetails.country.value),
          postalCode: trimIfString(brokerDetails.postalCode),
          streetLines: generateStreetLinesArray(
            brokerDetails.addressLine1,
            brokerDetails.addressLine2,
            brokerDetails.addressLine3
          ),
          city: trimIfString(brokerDetails.cityTown),
          stateOrCounty: brokerDetails.countyStateProvince ? trimIfString(brokerDetails.countyStateProvince) : null,
          residential: false
        },
        notificationDetails: {
          email: trimIfString(brokerDetails.email),
          mobile: trimIfString(brokerDetails.telephoneNumber)
        }
      }
    }
    return details;
  }

  const getBrokerDetails = () => {
    return {
      contactDetails: {
        name: trimIfString(brokerDetails.contactName),
        telephone: trimIfString(brokerDetails.telephoneNumber)
      },
      address: {
        organisation: trimIfString(brokerDetails.companyName),
        countryCode: trimIfString(brokerDetails.country.value),
        postalCode: trimIfString(brokerDetails.postalCode),
        streetLines: generateStreetLinesArray(
          brokerDetails.addressLine1,
          brokerDetails.addressLine2,
          brokerDetails.addressLine3,
        ),
        city: trimIfString(brokerDetails.cityTown),
        stateOrCounty: brokerDetails.countyStateProvince ? trimIfString(brokerDetails.countyStateProvince) : null,
        residential: false
      },
      notificationDetails: {
        email: trimIfString(brokerDetails.email),
        mobile: trimIfString(brokerDetails.telephoneNumber)
      }
    }
  }


  const getCommodities = () => {

    if (
      getTradeRoute(customer, booking) !== TradeRoute.DOMESTIC
      && shipmentType !== ShipmentType.NON_DOCS
      && getCarrierName(booking.selectedQuote.quoteId) === 'dhl'
      && !booking.customsDetails.commodities[0].commodityCode
    ) {
      return null;
    }

    return booking.customsDetails.commodities.map((commodity: Commodity) => {
      if (
        getTradeRoute(customer, booking) !== TradeRoute.DOMESTIC
        && shipmentType === ShipmentType.DOCS
        && getCarrierName(booking.selectedQuote.quoteId) === 'fedex'
      ) {
        return {
          countryOfOrigin: booking.countryOfOrigin.value
        } as Commodity
      } else {
        return {
          description: commodity.description ? trimIfString(commodity.description) : null,
          commodityCode: commodity.commodityCode ? trimIfString(removePeriodsFromString(commodity.commodityCode)) : null,
          quantity: commodity.quantity?.value ? trimIfString(commodity.quantity) : null,
          unitPrice: commodity.unitPrice?.value ? trimIfString(commodity.unitPrice) : null,
          weight: commodity.weight?.value ? trimIfString(commodity.weight) : null,
          countryOfOrigin: booking.countryOfOrigin.value
        }
      }
    })
  }

  const getCollectionDetails = () => {
    let details = null;

    if (booking.selectedQuote.serviceType === 'COLLECTED') {
      details = {
        contactDetails: {
          name: trimIfString(collectionDetails.contactName),
          telephone: trimIfString(collectionDetails.telephoneNumber)
        },
        address: {
          organisation: trimIfString(collectionDetails.companyName),
          countryCode: trimIfString(booking.origin.value),
          postalCode: trimIfString(formatCollectionPostalCode(booking)),
          streetLines: generateStreetLinesArray(collectionDetails.addressLine1, collectionDetails.addressLine2, collectionDetails.addressLine3),
          city: trimIfString(booking.originCityTown),
          stateOrCounty: collectionDetails.countyStateProvince ? trimIfString(collectionDetails.countyStateProvince) : null,
          residential: booking.originResidential
        },
        notificationDetails: {
          email: trimIfString(collectionDetails.email),
          mobile: trimIfString(collectionDetails.telephoneNumber)
        }
      }
    }

    return details;
  }

  const getSenderDetails = () => {
    let details = {
      contactDetails: {
        name: trimIfString(senderDetails.contactName),
        telephone: trimIfString(senderDetails.telephoneNumber)
      },
      address: {
        organisation: trimIfString(senderDetails.companyName),
        countryCode: trimIfString(senderDetails.country.value),
        postalCode: trimIfString(senderDetails.postalCode),
        streetLines: generateStreetLinesArray(senderDetails.addressLine1, senderDetails.addressLine2, senderDetails.addressLine3),
        city: trimIfString(senderDetails.cityTown),
        stateOrCounty: senderDetails.countyStateProvince ? trimIfString(senderDetails.countyStateProvince) : null,
        residential: booking.originResidential,
        collectedAddress: false
      },
      notificationDetails: {
        email: trimIfString(senderDetails.email),
        mobile: trimIfString(senderDetails.telephoneNumber)
      }
    }

    if (
      booking.selectedQuote.serviceType === 'COLLECTED'
      && booking.trueShipper
    ) {
      details = {
        contactDetails: {
          name: trimIfString(collectionDetails.contactName),
          telephone: trimIfString(collectionDetails.telephoneNumber)
        },
        address: {
          organisation: trimIfString(collectionDetails.companyName),
          countryCode: trimIfString(booking.origin.value),
          postalCode: trimIfString(booking.originPostalCode),
          streetLines: generateStreetLinesArray(collectionDetails.addressLine1, collectionDetails.addressLine2, collectionDetails.addressLine3),
          city: trimIfString(booking.originCityTown),
          stateOrCounty: collectionDetails.countyStateProvince ? trimIfString(collectionDetails.countyStateProvince) : null,
          residential: booking.originResidential,
          collectedAddress: true
        },
        notificationDetails: {
          email: trimIfString(collectionDetails.email),
          mobile: trimIfString(collectionDetails.telephoneNumber)
        }
      }
    }

    return details;
  }

  const generateElectronicTradeDetails = () => {
    let electronicTradeDetails: any = {
      uploadedDocuments: null,
      requestedDocuments: null
    };

    const docs = appData.uploadedDocuments;

    const signatureFile = docs.find((doc: any) => doc.type.title === 'Signature');

    if (
      appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE
      && signatureFile
    ) {
      electronicTradeDetails.requestedDocuments = [{
        documentDate: appData.invoice.date ? dayjs(appData.invoice.date).format('YYYY-MM-DD') : null,
        invoiceReference: appData.invoice.number ? appData.invoice.number : booking.hawb,
        signatureTitle: trimIfString(appData.invoice.position),
        signatureName: trimIfString(appData.invoice.name),
        signatureImage: signatureFile.documentContent,
        type: ElectronicTradeDocumentType.COMMERCIAL_INVOICE
      }]
    } else if (
      appData.invoice.attachOrUpload === AttachOrUpload.UPLOAD
      && appData.uploadedDocuments.length > 0
    ) {
      electronicTradeDetails.uploadedDocuments = appData.uploadedDocuments.map((document: any) => {
        if (document.type === ElectronicTradeDocumentType.COMMERCIAL_INVOICE) {
          return {
            type: document.type.value,
            contentType: document.contentType,
            documentContent: document.documentContent,
            invoiceReference: trimIfString(document.reference),
            documentDate: appData.invoice.date ? dayjs(appData.invoice.date).format('YYYY-MM-DD') : null
          }
        } else {
          return {
            type: document.type.value,
            contentType: document.contentType,
            documentContent: document.documentContent,
            invoiceReference: trimIfString(document.reference)
          }
        }
      })
    }

    return electronicTradeDetails.uploadedDocuments || electronicTradeDetails.requestedDocuments ? electronicTradeDetails : null;
  }

  const populateSpecialServices = () => {
    let specialServices: SpecialServiceType[] = [];

    if (booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP) {
      specialServices.push(SpecialServiceType.DELIVERED_DUTY_PAID);
    }

    if (
      appData.invoice.attachOrUpload === AttachOrUpload.UPLOAD
      || appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE
    ) {
      specialServices.push(SpecialServiceType.ELECTRONIC_TRADE_DOCUMENTS);
    }

    if (booking.insure) {
      specialServices.push(SpecialServiceType.INSURANCE);
    }

    return specialServices.length > 0 ? specialServices : null;
  }

  const getTaxDetails = () => {
    let taxDetails: any[] | null = [];

    if (senderDetails.taxNumbers[0].value) {
      for (const taxObject of senderDetails.taxNumbers) {
        if (taxObject.value) taxDetails.push({
          ...taxObject,
          value: trimIfString(taxObject.value),
          party: 'SENDER'
        });
      }
    }

    if (!senderDetails.generateECS) {
      if (senderDetails.exportComplianceStatement.edn && getCarrierName(booking.selectedQuote.quoteId) === 'dhl') {
        taxDetails.push({type: 'FTZ', value: senderDetails.exportComplianceStatement.edn, party: 'SENDER'});
      } else if (senderDetails.exportComplianceStatement.ccn) {
        taxDetails.push({type: 'FTZ', value: senderDetails.exportComplianceStatement.ccn, party: 'SENDER'})
      }
    }

    if (deliveryDetails.taxNumbers[0].value) {
      for (const taxObject of deliveryDetails.taxNumbers) {
        if (taxObject.value) taxDetails.push({
          ...taxObject,
          value: trimIfString(taxObject.value),
          party: 'RECIPIENT'
        });
      }
    }

    if (brokerDetails.taxNumbers[0].value) {
      for (const taxObject of brokerDetails.taxNumbers) {
        if (taxObject.value) taxDetails.push({
          ...taxObject,
          value: trimIfString(taxObject.value),
          party: 'THIRD_PARTY'
        });
      }
    }

    if (taxDetails.length < 1) {
      taxDetails = null;
    }

    return taxDetails as any;
  }

  const getStatementType = () => {
    let statementType = null;
    if (
      booking.origin.value === 'US'
      && booking.destination.value !== 'US'
      && booking.shipmentType === ShipmentType.NON_DOCS
      && senderDetails.exportComplianceStatement.aes
    ) {
      statementType = 'DEPARTMENT_OF_COMMERCE';
    }
    return statementType;
  }

  const getDeclaredCharges = async () => {
    let declaredCharges: any = [];
    const pushAsync = (array: any[], item: any) => new Promise<void>(resolve => {
      array.push(item);
      resolve();
    })
    if (booking.freightCharge) {
      await pushAsync(declaredCharges, {
        type: 'FREIGHT',
        currency: booking.preferredCurrency.value,
        value: (+booking.freightCharge).toFixed(2)
      });
    } else {
      declaredCharges = null;
    }
    return declaredCharges;
  }

  const getInvoiceDate = () => {
    let invoiceDate = null;
    if (
      carrier === 'dhl'
      && shipmentType === ShipmentType.NON_DOCS
    ) {
      invoiceDate = dayjs(appData.invoice.date).format('YYYY-MM-DD');
    }
    return invoiceDate;
  }

  const populateExportComplianceStatement = () => {
    let ecsInput = null;
    if (senderDetails.exportComplianceStatement.aes) ecsInput = trimIfString(senderDetails.exportComplianceStatement.aes);
    if (senderDetails.generateECS) ecsInput = null;
    if (senderDetails.exportComplianceStatement.edn && !senderDetails.generateECS) {
      ecsInput = `EDN:${trimIfString(senderDetails.exportComplianceStatement.edn)}`;
    }
    if (senderDetails.exportComplianceStatement.ccn && !senderDetails.generateECS) {
      ecsInput = `CCN:${trimIfString(senderDetails.exportComplianceStatement.ccn)}`
    }
    if (senderDetails.exportComplianceStatement.exporterCode) {
      ecsInput = `EC:${trimIfString(senderDetails.exportComplianceStatement.exporterCode)}`
    }
    return ecsInput;
  }

  const calculateTotalShipmentValue = () => {
    let totalShipmentValue = +booking.totalShipmentValue;

    if (
      !totalShipmentValue
      && carrier === 'dhl'
      && getTradeRoute(customer, booking) !== TradeRoute.DOMESTIC
      && booking.shipmentType === ShipmentType.DOCS
    ) totalShipmentValue = 0.01;

    return totalShipmentValue;
  }

  const requestBody: BookingRequestBody = {
    quoteId: quote.quoteId,
    customerAccountNumber: customer.creditCheck.tmffPartyId,
    shipmentDateTime: generateShipmentDateTime(booking.readyDate),
    customerCloseTime: collectionDetails ? collectionDetails.pickUpTo.slice(0, 5) : '',
    collectionDetails: getCollectionDetails(),
    senderDetails: getSenderDetails(),
    deliveryDetails: {
      contactDetails: {
        name: trimIfString(deliveryDetails.contactName),
        telephone: trimIfString(deliveryDetails.telephoneNumber)
      },
      address: {
        organisation: trimIfString(deliveryDetails.companyName),
        countryCode: trimIfString(booking.destination.value),
        postalCode: trimIfString(booking.destinationPostalCode),
        streetLines: generateStreetLinesArray(deliveryDetails.addressLine1, deliveryDetails.addressLine2, deliveryDetails.addressLine3),
        city: trimIfString(booking.destinationCityTown),
        stateOrCounty: deliveryDetails.countyStateProvince ? trimIfString(deliveryDetails.countyStateProvince) : null,
        residential: trimIfString(booking.destinationResidential)
      },
      notificationDetails: {
        email: trimIfString(deliveryDetails.email),
        mobile: trimIfString(deliveryDetails.telephoneNumber)
      }
    },
    parcels: booking.mappedPieces,
    customerShippingReference: trimIfString(booking.hawb),
    contentDescription: trimIfString(booking.contentDescription),
    label: generateLabel(booking.preferences.labelType, customer),
    shipmentType,
    packageLocation: collectionDetails.packageLocation,
    deliveryInstructions: deliveryDetails.additionalInfo ? trimIfString(deliveryDetails.additionalInfo) : null,
    remarks: trimIfString(collectionDetails.additionalInfo),
    bookingConfirmationEmail: trimIfString(booking.preferences.email),
    amiCarrierServiceName: booking.selectedQuote.carrierServiceName,
    specialServices: populateSpecialServices(),
    customsDetails: {
      windsorFr: "",
      statementType: getStatementType(),
      exportComplianceStatement: populateExportComplianceStatement(),
      b13AFilingOption: senderDetails.exportComplianceStatement.b13AFilingOption,
      amount: {
        value: calculateTotalShipmentValue(),
        currency: booking.preferredCurrency.value
      },
      invoiceDetails: {
        ...booking.customsDetails.invoiceDetails,
        cpc: booking.customsDetails.invoiceDetails.cpc ? trimIfString(booking.customsDetails.invoiceDetails.cpc) : null,
        taxDetails: getTaxDetails(),
        exportReason: isIntraEu(booking) ? null : booking.reasonForExport,
        declaredCharges: await getDeclaredCharges().then((response: any) => {return response}),
        invoiceDate: getInvoiceDate(),
        invoiceNumber: getInvoiceNumber(booking, appData),
        declarationStatement: booking.customsDetails.invoiceDetails.declarationStatement ? booking.customsDetails.invoiceDetails.declarationStatement : null
      },
      electronicTradeDetails: generateElectronicTradeDetails(),
      importerOfRecordDetails: getImporterOfRecordDetails(),
      commodities: getCommodities(),
      declaredValue: booking.totalShipmentValue ? +booking.totalShipmentValue : null
    },
    brokerDetails: booking.brokerDetails.brokerSelected ? getBrokerDetails() : null,
    logonEmail: customer.logonEmail,
    unitOfCurrency: booking.preferredCurrency.value,
    unitOfMeasurement: booking.imperialMetric
  }

  const bookingResponse: any = await bookingIGCall(requestBody, apiConfig, dispatch);

  let bookingErrors: any = [];
  const acceptableAlertCodes = ['30001', '30043', '30044', '30051', '30054', '30058', '30060'];
  if (getCarrierName(booking.selectedQuote.quoteId) === 'ups') acceptableAlertCodes.push('1');

  try {
    if (bookingResponse.shipmentId) {
      const alerts = bookingResponse.alerts;
      if (alerts && alerts.length > 0) {
        for (const alert of alerts) {
          if (alert.code && !acceptableAlertCodes.includes(alert.code)) bookingErrors.push(alert);
        }
      }
    } else if (bookingResponse.errors?.length > 0) {
      for (const error of bookingResponse.errors) {
        bookingErrors.push({
          code: error.code,
          message: error.message,
          fieldName: error.fieldName ? error.fieldName : 'Error'
        })
      }
    } else if (bookingResponse.credit !== undefined && bookingResponse.credit === false) {
      bookingErrors.push({
        code: '',
        message: 'You are out of credit, please contact Credit Control.\n ' +
          'If you have recently made a payment, please wait for your credit status to update.\n ' +
          'If you are still unable to place a booking, please get in contact with us.',
        fieldName: 'Customer out of Credit'
      })
    } else createAndLogError(customer, bookingResponse, requestBody, bookingErrors, apiConfig);
  } catch (error) {
    createAndLogError(customer, bookingResponse, requestBody, bookingErrors, apiConfig);
  }

  if (bookingErrors.length > 0) dispatch(bookingActions.updateIgBookingErrors(bookingErrors));

  return bookingResponse;
}

const createAndLogError = (customer: CustomerDetails, bookingResponse: any, requestBody: BookingRequestBody, bookingErrors: any, apiConfig: ApiConfig) => {
  const error = {
    customerAccountNumber: customer.creditCheck.tmffPartyId,
    logonEmail: customer.logonEmail,
    message: 'Error while placing booking.',
    error: {
      bookingResponse,
      bookingRequest: requestBody
    },
    quoteId: requestBody.quoteId
  }

  logError(error, apiConfig);

  bookingErrors.push({
    code: '',
    message: 'An error has occurred please contact customer services for more information.',
    fieldName: 'Error'
  })
}

export const convertErrorToSentence = (error: string) => {
  if (error) {
    const spaced = error.split('.');

    let shortenedArray = [];
    if (spaced.length > 2) shortenedArray.push(spaced[0], spaced[spaced.length - 1]);
    else shortenedArray = spaced;

    let capitalizedArray = [];
    for (const section of shortenedArray) {
      capitalizedArray.push(camelCaseToSentence(section));
    }

    return capitalizedArray.join(' ');
  }
}

export const getTermsAndConditions = (countryCode: string) => {
  switch (countryCode) {
    case 'GB':
      return '/TermsAndConditions_GB.pdf';
    case 'AU':
      return '/TermsAndConditions_AU.pdf';
    case 'NZ':
      return '/TermsAndConditions_NZ.pdf';
    case 'US':
      return '/TermsAndConditions_US.pdf';
    case 'ZA':
      return '/TermsAndConditions_ZA.pdf';
    case 'NL':
      return '/TermsAndConditions_NL.pdf';
    default:
      return '';
  }
}

export const formatCollectionPostalCode = (booking: Booking) => {
  if (booking.origin.value === "GB") {
    const code = booking.originPostalCode.replace(/\s/g, "");
    const newCode = code.split("");
    newCode.splice(-3, 0, " ");
    return newCode.join("");
  } else {
    return booking.originPostalCode;
  }
}