import axios from "axios";
import { CustomerDetails } from "../features/customerSlice";
import { Booking } from "../features/bookingSlice";
import { ApiConfig, appDataActions } from "../features/appDataSlice";
import { AppDispatch } from "../app/store";

export enum TradeRoute {
  DOMESTIC = 'domestic',
  EXPORT = 'export',
  IMPORT = 'import',
  CROSSTRADE = 'cross-trade'
}

export enum RuleSet {
  ON_START = 'onStart',
  BOOKING = 'booking',
  B13A = 'b13a'
}

export function getTradeRoute(customer: CustomerDetails, booking: Booking) : TradeRoute | null {

  switch (true) {
    case booking.origin.value === booking.destination.value:
      return  TradeRoute.DOMESTIC;
    case booking.origin.value !== booking.destination.value && booking.origin.value === customer.customerCountryCode:
      return  TradeRoute.EXPORT
    case booking.origin.value !== booking.destination.value && booking.destination.value === customer.customerCountryCode:
      return  TradeRoute.IMPORT
    case booking.origin.value !== booking.destination.value && (
      booking.origin.value !== customer.customerCountryCode && booking.destination.value !== customer.customerCountryCode
    ):
      return  TradeRoute.CROSSTRADE
  }
  return null;
}

export function isIntraEu(booking: Booking) {
  return booking.destination.tradeBloc === "EUCU" && booking.origin.tradeBloc === "EUCU"
}

async function callRulesEngine(ruleSet: String, customer: CustomerDetails, eventData: any, config: ApiConfig) {
  const url = config.headers['IdToken'] ? `${process.env.REACT_APP_NEBE_API_URL}/white-label/indirect/v1/rules` : `${process.env.REACT_APP_NEBE_API_URL}/v3/rules/run-engine`;
  return axios.post(
    url,
    {
      ruleSet: ruleSet,
      customer: customer,
      eventData: eventData
    },
    config
  ).then((response: any) => {
    return response.data["results"];
  }, (error: any) => {
    console.error('Rules engine error', error.message, { stack: error.stack });
  })
}

export async function handleRules(ruleSet: RuleSet, customer: CustomerDetails, eventData: any, dispatch: AppDispatch, apiConfig: ApiConfig) {
  try {
    const results = await callRulesEngine(ruleSet, customer, eventData, apiConfig);
    dispatch(appDataActions.setCustomRules(results));
  } catch (error: any) {
    console.error(`Error handling ${ruleSet} rules: ${error.message}`, { stack: error.stack });
  }
}


export function getRule(rules: { rule: string, value: any }[], rule: string) {
  const result = rules?.find((customRule: any) => customRule.rule === rule);
  return result?.value ?? false;
}