import React, { useEffect, useState } from 'react';
import './FinishPage.scss';
import { useAppSelector } from '../../../app/hooks';
import { bookingActions, BookingResult, bookingSelector } from '../../../features/bookingSlice';
import AMISteps, { ActivePage } from '../../../steps/Steps';
import { AMIAlert, AMIButton, AMIFormElement, AMIInput, AMISpinner, Icon } from '../../../ui-components/UiComponents';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appDataActions, appDataSelector, OwnOrGenerate } from '../../../features/appDataSlice';
import { addAddress, setTemplateData } from '../../../utilities/APIUtilities';
import { customerActions, customerDetailsSelector } from '../../../features/customerSlice';
import {downloadDocuments, downloadLabel, getCarrierName, getUpsUrl, ServiceType} from '../../../utilities/HelperUtilities';
import { formatAddressToSave } from './FinishPageUtilities';
import {retrieveContactNo, retrieveContent} from "../../../utilities/ContentRetrieverUtilities";
import { convertToTemplateFromBooking } from '../../../utilities/TemplateHandler';
import { createTemplateRequestBody } from '../../account-page/account-templates/utils';

const FinishPage = () => {

  const customer = useAppSelector(customerDetailsSelector);
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const bookingResult = booking.bookingResult as BookingResult;
  const parcelNumbers = bookingResult?.consignmentDetailsList[0].parcelNumbers
  const serviceType = booking.selectedQuote.serviceType;
  const dropInDetails = booking.dropInDetails;
  const dropInSelected = appData.dropInSelected;
  const carrier = getCarrierName(booking.selectedQuote.quoteId);
  const apiConfig = appData.apiConfig;
  const isWlDomainOrigin = appData.isWl;

  const [fetchingLabels, setFetchingLabels] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState(false);
  const [fetchingDocuments, setFetchingDocuments] = useState(false);
  const [documentDownloadFailed, setDocumentDownloadFailed] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [isSavingTemplate, setIsSavingTemplate] = useState(false);
  const [showSaveTemplateSuccess, setShowSaveTemplateSuccess] = useState(false);

  const onLabelsClick = async() => {
    setDownloadFailed(false);
    setFetchingLabels(true);
    await downloadLabel(apiConfig, bookingResult, customer);
    setFetchingLabels(false);
  }

  const onDocumentsClick = async() => {
    setDocumentDownloadFailed(false);
    setFetchingDocuments(true);
    await downloadDocuments(customer, bookingResult?.amiReference)
      .then((result: any) => {
        if (result) {
          return result;
        } else {
          setDocumentDownloadFailed(true);
        }
      })
      .catch((error: any) => {console.error(error)});
    setFetchingDocuments(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSavingAddresses();

    return () => {
      if (history.action === "POP") {
        history.push('/single-booking')
      }
      dispatch(bookingActions.resetState());
      dispatch(appDataActions.resetStateWithConditions());
    };
  }, [])

  const [contactNumber, setContactNumber] = useState<any>()

  useEffect(() => {
    retrieveContactNo(customer, apiConfig)
      .then((result: any) => {
        setContactNumber(result)
      })
      .catch((error: any) => console.error(error));
  }, [])

  const [importantInfoGB, setImportantInfoGB] = useState<any>()

  useEffect(() => {
    retrieveContent('importantInfo', 'GB', customer, apiConfig)
      .then((result: any) => setImportantInfoGB(result))
      .catch(error => console.error(error))
  }, [])

  const handleSavingAddresses = () => {
    const addressesToSave = [];
    if (appData.saveCollectionAddress) {
      addressesToSave.push(formatAddressToSave(customer, booking.collectionDetails, booking.origin, booking.originPostalCode));
    }
    if (appData.saveShipperAddress) {
      addressesToSave.push(formatAddressToSave(customer, booking.shipperDetails, booking.shipperDetails.country, booking.shipperDetails.postalCode));
    }
    if (appData.saveConsigneeAddress) {
      addressesToSave.push(formatAddressToSave(customer, booking.consigneeDetails, booking.destination, booking.destinationPostalCode));
    }
    if (appData.saveBrokerAddress) {
      addressesToSave.push(formatAddressToSave(customer, booking.brokerDetails, booking.brokerDetails.country, booking.brokerDetails.postalCode));
    }

    for (const address of addressesToSave) {
      addAddress(address, apiConfig);
    }
  }

  const collectionBooked = bookingResult?.pickupConfirmationCode;
  const showSuccess = collectionBooked || serviceType === ServiceType.DROP_IN;

  const onSaveTemplateClick = async() => {
    setIsSavingTemplate(true);
    const convertedTemplate = convertToTemplateFromBooking(booking, templateName, 'SINGLE');
    const updatedTemplates = [...customer.templates, convertedTemplate];
    const requestBody = createTemplateRequestBody(customer, updatedTemplates);
    const response = await setTemplateData(requestBody, appData.apiConfig)
    if (response?.status === 200) {
      dispatch(customerActions.setTemplates(requestBody.item.templates));
      setShowSaveTemplateSuccess(true);
    } else {
      window.alert('An error occurred while trying to save this template. Please try again.')
    }

    setIsSavingTemplate(false);
  }

  const onFeedbackClick = () => {
    const script = document.getElementsByClassName("atlwdg-trigger")[0] as HTMLElement;
    script.click();
  }

  return (
    <div className="finish-page">

      <AMISteps activePage={ActivePage.FINISH} />

      <div
        className="finish-page__grid"
        id="finish-page"
      >
        <div className="finish-page__grid__content">

          {
            showSuccess
              ? <p className="finish-page__grid__content__header">Success</p>
              : <p className="finish-page__grid__content__header" style={{color: 'var(--warning)'}}>Successful Booking / No Collection</p>
          }
          <hr style={
            showSuccess
              ? {backgroundColor: 'var(--success)'}
              : {backgroundColor: 'var(--warning)'}
          }/>

          <div className="finish-page__grid__content__main-grid">
            <Icon
              name="CircleCheck"
              color={
                showSuccess
                  ? 'var(--success)'
                  : 'var(--warning)'
              }
              style={{
                width: '72px',
                height: '72px'
              }}
            />

            <div className="finish-page__grid__content__main-grid__right">
              {
                collectionBooked
                && serviceType === ServiceType.COLLECTED
                && <p>Your collection has been arranged.</p>
              }

              {
                !collectionBooked
                && serviceType === ServiceType.COLLECTED
                && (
                  <div>
                    <p>Your booking has been placed and you may download your label below. However, we were <strong style={{color: 'var(--error)'}}>not</strong> able to arrange collection.</p>
                    <p><strong>Please contact customer services to arrange collection.</strong></p>
                  </div>
                )
              }

              {
                serviceType === ServiceType.DROP_IN
                && (
                  <div className="finish-page__grid__content__main-grid__right__drop-in-details">
                    {dropInSelected && (
                      <>
                        <p>Your shipment has been arranged. Please drop your shipment in at:</p>
                        <div>
                          <h4>{dropInDetails.companyName}</h4>
                          <p>{dropInDetails.addressLine1}</p>
                          {dropInDetails.addressLine2 && <p>{dropInDetails.addressLine2}</p>}
                          <p>{dropInDetails.cityTown}</p>
                          <p>{dropInDetails.countyStateProvince}</p>
                          <p>{dropInDetails.countryCode}</p>
                          <p>{dropInDetails.postalCode}</p>
                        </div>

                        <p>Telephone: {dropInDetails.telephoneNumber}</p>
                      </>
                    )}

                    {!dropInSelected && carrier !== "ups" && (
                      <p>Your shipment has been arranged. Please drop your shipment into your nearest AMI receiving centre.</p>
                    )}

                    {serviceType === 'DROP_IN' && carrier === "ups" && (
                      <p>Your shipment has been arranged.
                        <br />A list of UPS drop-in points can be found <a href={getUpsUrl(booking)} target="_blank" rel="noreferrer">here</a>
                      </p>
                    )}
                  </div>
                )
              }

              {bookingResult?.amiReference
                && <p>Booking Reference: <span>{bookingResult?.amiReference}</span></p>
              }

              {collectionBooked
                && <p>Collection Reference: <span>{bookingResult?.pickupConfirmationCode}</span></p>
              }

              <p style={{marginTop: '24px'}}>Downloads:</p>
              <div className="finish-page__grid__content__main-grid__right__download-btns-container">

                {parcelNumbers && parcelNumbers.length > 0
                && (
                  <AMIButton
                    variant="primary"
                    size="large"
                    isSpinning={fetchingLabels}
                    onClick={onLabelsClick}
                  >
                    <Icon
                      name="Download"
                      color="white"
                    />
                    Labels
                  </AMIButton>
                )}

                {parcelNumbers && parcelNumbers.length < 1
                  && <AMIButton
                      variant="primary"
                      disabled
                      onClick={() => null}
                    >
                      <Icon
                        name="Download"
                        color="white"
                      />
                      Labels Unavailable
                    </AMIButton>
                  }

                {appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE && (
                  <AMIButton
                    variant="secondary"
                    size="large"
                    isSpinning={fetchingDocuments}
                    onClick={onDocumentsClick}
                  >
                    <Icon
                      name="Download"
                      color="white"
                    />
                    Invoice
                  </AMIButton>
                )}

                {getCarrierName(booking.selectedQuote.quoteId) === 'toll' && (
                  <AMIButton
                    variant="secondary"
                    size="large"
                    style={{padding: '12px'}}
                    isSpinning={fetchingDocuments}
                    onClick={onDocumentsClick}
                  >
                    <Icon
                      name="Download"
                      color="white"
                    />
                    End of Day Manifest
                  </AMIButton>
                )}

              </div>

              {!fetchingLabels && downloadFailed && (
                <div>
                  <p>Labels failed to download. Please
                    <span className="finish-page__main-grid__right__retry-span" onClick={onLabelsClick}> click here</span> to try again
                  </p>
                </div>
              )}

              {!fetchingDocuments
                && documentDownloadFailed
                && appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE
                && (
                  <p>Generated invoice is not ready please try again in 2 minutes</p>
                )
              }

              <div className="finish-page__grid__content__main-grid__right__template-cont">
              <h2>Template</h2>

              <div className="finish-page__grid__content__main-grid__right__template-cont__content">
                <p>Save this booking as a template for future bookings?</p>
                <p>If you often make similar bookings to this one then saving this booking as a template will save you time in the future.</p>
                <p>Applying a template will pre populate the relevant fields across the site.</p>

                <div className="finish-page__grid__content__main-grid__right__template-cont__content__save-cont">
                  <AMIFormElement label="Enter a name for this template:">
                    <AMIInput
                      name="templateName"
                      placeholder="i.e. International Clothes Export"
                      size="large"
                      onChange={(event) => setTemplateName(event.target.value)}
                    />
                  </AMIFormElement>
                  <AMIButton
                    variant="default"
                    size="large"
                    disabled={!templateName}
                    onClick={() => onSaveTemplateClick()}
                  >
                    {!isSavingTemplate && (
                      <Icon
                        name="Save"
                        style={{
                          transform: "translateY(2px)",
                          fill: templateName ? 'inherit' : 'var(--textLight)'
                        }}
                      />
                    )}
                    {isSavingTemplate && (
                      <AMISpinner
                        style={{
                          width: "24px",
                          height: "24px"
                        }}
                      />
                    )}
                    Save Template
                  </AMIButton>

                  {showSaveTemplateSuccess && (
                    <AMIAlert
                      variant="info"
                    >
                      Template successfully saved
                    </AMIAlert>
                  )}
                </div>
              </div>
              </div>


              <div className="finish-page__grid__content__feedback">
                <h2>Feedback?</h2>
                <p>We would love to hear from you whether you enjoyed your experience or thought we could do something better. Please use the button below to provide us with your feedback.</p>
                <AMIButton
                  variant="default"
                  size="large"
                  onClick={onFeedbackClick}
                >Feedback</AMIButton>
              </div>
            </div>
          </div>
        </div>
        <div className="finish-page__grid__important-notes vertical-card">
          <p className="card-header">Important Notes</p>

          {customer.customerCountryCode === "GB"
            && <div dangerouslySetInnerHTML={{__html: importantInfoGB}}></div>
          }

          {isWlDomainOrigin && <>
            <p>Please ensure label(s) are printed and attached to the package(s) prior to collection.</p>
            <p><strong>IMPORTANT:</strong> Should you need to CANCEL this booking, please contact customer services. We regret charges may still be incurred.</p>
          </>}

          {!isWlDomainOrigin && <>
            <p>If the shipment is to be collected from your customer, please forward the label(s) to your customer requesting that they print the label(s) and attach to the package(s).</p>
            <p>Require assistance? Click on &apos;Live Chat&apos; below - our team are always available to assist.</p>
            <p><strong>IMPORTANT:</strong> Should you need to CANCEL this booking, please call <a href={"tel: "+ contactNumber}>{contactNumber}</a>. We regret charges may still be incurred.</p>
          </>}
        </div>
      </div>

    </div>
  )
}

export default FinishPage;