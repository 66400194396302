import React, { useEffect, useState } from 'react';
import CustomerPage from './customer-page/CustomerPage';
import { useAppSelector } from '../../../../app/hooks';
import './WhiteLabelCustomersPage.scss';
import { AMIButton, AMIFormElement, AMIInput, AMIModal, AMISelect, AMISpinner } from '../../../../ui-components/UiComponents';
import { formatCounties, generateStreetLinesArray, getPostalCodeRegex } from '../../../../utilities/HelperUtilities';
import { getCountryInfo, getWlEntities, postWlEntity } from '../../../../utilities/APIUtilities';
import { appDataSelector } from '../../../../features/appDataSlice';
import { isBetweenXAndXCharacters, isValidPostalCode } from '../../../../utilities/ValidationUtilities';

const BLANK_CUSTOMER = {
  companyName: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  cityTown: '',
  countyStateProvince: '',
  country: {
    title: '',
    value: ''
  },
  postalCode: ''
};

const WhiteLabelCustomersPage = () => {

  const { apiConfig, countries } = useAppSelector(appDataSelector);

  const [activeCustomer, setActiveCustomer] = useState<any>();
  const [showAddNewCustomerModal, setShowAddNewCustomerModal] = useState(false);
  const [newCustomer, setNewCustomer] = useState(BLANK_CUSTOMER);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [counties, setCounties] = useState([]);
  const [postalCodeRegex, setPostalCodeRegex] = useState();
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    getAndSetCustomers()
  }, [])

  const getAndSetCustomers = async() => {
    setIsFetching(true);
    const response = await getWlEntities(apiConfig) as any;
    if (response?.data) setCustomers(response.data);
    setIsFetching(false);
  }

  const onFieldChange = (field: string, value: string) => {
    setNewCustomer(prevState => {
      return {
        ...prevState,
        [field]: value
      }
    })
  }

  const getStateOrCounty = () => {
    let county: any = newCustomer.countyStateProvince;
    if (county) {
      if (county.value) county = county.value;
    } else county = null;

    return county;
  }

  const onSaveClick = async() => {
    setIsSaving(true);
    setShowErrors(false);

    for (const error in errHandler) {
      if (!(errHandler as any)[error].criteria) {
        setShowErrors(true);
        setIsSaving(false);
        return;
      }
    }

    const body = {
      address: {
        city: newCustomer.cityTown,
        countryCode: newCustomer.country.value,
        organisation: newCustomer.companyName,
        postalCode: newCustomer.postalCode,
        stateOrCounty: getStateOrCounty(),
        streetLines: generateStreetLinesArray(newCustomer.addressLine1, newCustomer.addressLine2, newCustomer.addressLine3)
      }
    }
    const response = await postWlEntity(body, apiConfig);
    if (response) {
      setShowAddNewCustomerModal(false);
      setNewCustomer(BLANK_CUSTOMER);
      getAndSetCustomers()
    } else {
      window.alert("An error occurred, please try again.")
    }
    setIsSaving(false);
  }

  const onCountryChange = async(country: any) => {
    onFieldChange('country', country);
    const countryInfo = await getCountryInfo(apiConfig, country.value);

    setPostalCodeRegex(getPostalCodeRegex(countryInfo));

    if (countryInfo.countiesStates.length > 0) setCounties(formatCounties(countryInfo.countiesStates))
    else setCounties([]);
  }

  const errHandler = {
    companyName: {
      criteria: isBetweenXAndXCharacters(newCustomer.companyName, 3, 35),
      message: 'Between 3 and 35 characters'
    },
    addressLine1: {
      criteria: isBetweenXAndXCharacters(newCustomer.addressLine1, 3, 35),
      message: 'Between 3 and 35 characters'
    },
    addressLine2: {
      criteria: newCustomer.addressLine2
        ? isBetweenXAndXCharacters(newCustomer.addressLine2, 3, 35)
        : true,
      message: 'Between 3 and 35 characters.'
    },
    addressLine3: {
      criteria: newCustomer.addressLine3
        ? isBetweenXAndXCharacters(newCustomer.addressLine3, 3, 35)
        : true,
      message: 'Between 3 and 35 characters.'
    },
    countyStateProvince: {
      criteria: counties.length > 0
        ? newCustomer.countyStateProvince
        : true,
      message: 'Required'
    },
    postalCode: {
      criteria: postalCodeRegex
        ? newCustomer.postalCode && isValidPostalCode(newCustomer.postalCode, postalCodeRegex)
        : true,
      message: 'Required. Must be a valid postal code'
    },
    cityTown: {
      criteria: newCustomer.cityTown,
      message: 'Between 3 and 35 characters'
    },
    country: {
      criteria: newCustomer.country.value,
      message: 'Required'
    }
  }

  return (
    <>
      <div className="customers-page">
        <div className="customers-page__top-line">
          <h2>Customers</h2>
          <p onClick={() => setShowAddNewCustomerModal(true)}>+ Add New Customer</p>
        </div>

        {isFetching && (
          <div className="wl-bookings__spinner">
            <AMISpinner />
            <p>Fetching customers...</p>
          </div>
        )}

        {customers.length > 0 && customers.map((el: any) => {
          return (
            <div onClick={() => setActiveCustomer(el)} key={el.id}>
              <CustomerPage
                entity={el}
                active={el.id === activeCustomer?.id}
              />
            </div>
          )
        })}
      </div>

      {showAddNewCustomerModal && (
        <AMIModal
          title="Add New Customer"
          width="90vw"
          buttons={[
            <AMIButton
              size="large"
              key={1}
              onClick={() => setShowAddNewCustomerModal(false)}
            >Close</AMIButton>,
            <AMIButton
              size="large"
              variant="primary"
              isSpinning={isSaving}
              key={2}
              onClick={onSaveClick}
            >Save</AMIButton>
          ]}
          close={() => setShowAddNewCustomerModal(false)}
        >
          <form className="white-label-add-new-customer-modal">
            <AMIFormElement
              label="Country Code"
              style={{gridColumn: "1 / 3"}}
              errorMessage={
                showErrors
                && !errHandler.country.criteria
                ? errHandler.country.message
                : ''
              }
            >
            <AMISelect
              name="brokerCountry"
              placeholder="Required"
              size="large"
              isSearchable
              defaultValue={newCustomer.country}
              options={countries}
              onChange={(event) => onCountryChange(event)}
            />
            </AMIFormElement>

            <AMIFormElement
              label="Company Name"
              errorMessage={
                showErrors
                && !errHandler.companyName.criteria
                ? errHandler.companyName.message
                : ''
              }
            >
              <AMIInput
                name="companyName"
                value={newCustomer.companyName}
                size="large"
                onChange={(event) => onFieldChange("companyName", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Address Line 1"
              errorMessage={
                showErrors
                && !errHandler.addressLine1.criteria
                ? errHandler.addressLine1.message
                : ''
              }
            >
              <AMIInput
                name="addressLine1"
                value={newCustomer.addressLine1}
                size="large"
                onChange={(event) => onFieldChange("addressLine1", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Address Line 2"
              errorMessage={
                showErrors
                && !errHandler.addressLine2.criteria
                ? errHandler.addressLine2.message
                : ''
              }
            >
              <AMIInput
                name="addressLine2"
                value={newCustomer.addressLine2}
                size="large"
                onChange={(event) => onFieldChange("addressLine2", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Address Line 3"
              errorMessage={
                showErrors
                && !errHandler.addressLine3.criteria
                ? errHandler.addressLine3.message
                : ''
              }
            >
              <AMIInput
                name="addressLine3"
                value={newCustomer.addressLine3}
                size="large"
                onChange={(event) => onFieldChange("addressLine3", event.target.value)}
              />
            </AMIFormElement>

            <AMIFormElement
              label="City/Town"
              errorMessage={
                showErrors
                && !errHandler.cityTown.criteria
                ? errHandler.cityTown.message
                : ''
              }
            >
              <AMIInput
                name="cityTown"
                value={newCustomer.cityTown}
                size="large"
                onChange={(event) => onFieldChange("cityTown", event.target.value)}
              />
            </AMIFormElement>

            {counties.length === 0 && (
              <AMIFormElement
                label="County/State/Province"
                errorMessage={
                  showErrors
                  && !errHandler.countyStateProvince.criteria
                  ? errHandler.countyStateProvince.message
                  : ''
                }
              >
                <AMIInput
                  name="countyStateProvince"
                  size="large"
                  value={newCustomer.countyStateProvince}
                  onChange={(event) => onFieldChange('countyStateProvince', event.target.value)}
                />
              </AMIFormElement>
            )}

            {counties.length > 0 && (
              <AMIFormElement
                label="County/State/Province"
                errorMessage={
                  showErrors
                  && !errHandler.countyStateProvince.criteria
                  ? errHandler.countyStateProvince.message
                  : ''
                }
              >
                <AMISelect
                  name="countyStateProvince"
                  placeholder="Required"
                  size="large"
                  isSearchable
                  options={counties}
                  onChange={(event) => onFieldChange('countyStateProvince', event)}
                  defaultValue={counties.find((item: any) => {
                    return item.value === newCustomer.countyStateProvince
                  })}
                />
              </AMIFormElement>
            )}

            <AMIFormElement
              label="Postal Code"
              errorMessage={
                showErrors
                && !errHandler.postalCode.criteria
                ? errHandler.postalCode.message
                : ''
              }
            >
              <AMIInput
                name="postalCode"
                value={newCustomer.postalCode}
                size="large"
                onChange={(event) => onFieldChange("postalCode", event.target.value)}
              />
            </AMIFormElement>

          </form>
        </AMIModal>
      )}
    </>
  )
}

export default WhiteLabelCustomersPage;