import React, { useEffect, useState } from 'react';
import './TrackingModal.scss';
import { AMIButton, AMIModal, AMISpinner, Icon } from '../../../../ui-components/UiComponents';
import { BookingHistoryItem, getShipmentTracking } from '../../../../utilities/APIUtilities';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { useAppSelector } from '../../../../app/hooks';
import dayjs from 'dayjs';
import {
  downloadDocuments,
  downloadLabel,
  getCarrierImage,
  trimIfString
} from '../../../../utilities/HelperUtilities';
import { appDataSelector } from '../../../../features/appDataSlice';

const TrackingModal: React.FC<{
  booking: BookingHistoryItem,
  closeModal: () => void
}> = ({
  booking,
  closeModal
}) => {

  const deliveryDetails = booking.deliveryDetails;
  const parcelNumbers = booking.consignmentDetailsList && booking.consignmentDetailsList[0].parcelNumbers;
  const { apiConfig, isWl } = useAppSelector(appDataSelector);

  const [trackingEvents, setTrackingEvents] = useState([]);
  const [isFetchingTrackingInfo, setIsFetchingTrackingInfo] = useState(true);
  const [fetchingLabels, setFetchingLabels] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState(false);

  const customer = useAppSelector(customerDetailsSelector);
  const isWlDomainOrigin = isWl;

  const getTrackingInfo = async() => {

    const trackingInfo = await getShipmentTracking(apiConfig, customer, booking);
    setIsFetchingTrackingInfo(false);
    setTrackingEvents(trackingInfo.trackingEvents);
  }

  const onLabelsClick = async() => {
    setDownloadFailed(false);
    setFetchingLabels(true);
    const isSuccessful = await downloadLabel(apiConfig, booking, customer);
    if (!isSuccessful) setDownloadFailed(true);
    setFetchingLabels(false);
  }

  const [fetchingDocuments, setFetchingDocuments] = useState(false);
  const [documentDownloadFailed, setDocumentDownloadFailed] = useState(false);

  const onDocumentsClick = async() => {
    setDocumentDownloadFailed(false);
    setFetchingDocuments(true);
    await downloadDocuments(customer, booking.amiReference)
      .then((result: any) => {
        if (result) {
          return result;
        } else {
          setDocumentDownloadFailed(true);
        }
      })
      .catch((error: any) => {console.error(error)})
    setFetchingDocuments(false);
  }

  useEffect(() => {
    getTrackingInfo();
  }, [])

  const formatStreetLines = (streetLines: string[]) => {
    if (streetLines.length === 1) {
      return streetLines[0] + ', ';
    } else {
      return streetLines[0] + ', ', + streetLines[1] + ', ';
    }
  }

  return (
    <AMIModal
      title={"Booking " + booking.amiReference}
      width="80%"
      close={closeModal}
    >
      <div className="tracking-modal-content">
        <img
          src={getCarrierImage(booking.carrierName)}
          alt="Logo"
          className="tracking-modal-content__carrier-logo"
        />

        <div className="tracking-modal-content__booking-info">
          <p><span>Booking Date:</span><br/>{dayjs(booking.shipmentDate).format('DD/MM/YYYY')}</p>
          <p><span>Your Ref:</span><br/>{booking.customerShippingReference}</p>
          <p><span>Shipment ID:</span><br/>{booking.shipmentId}</p>
          <p><span>{isWlDomainOrigin ? 'ExpressFr8 Reference' : 'AMI Reference'}:</span><br/>{booking.amiReference}</p>
          <p><span>Destination:</span><br/>{booking.deliveryDetails.address.city}, {booking.deliveryDetails.address.countryCode}</p>
        </div>

        <div className="tracking-modal-content__consignee-details">
          <h3>Consignee</h3>
          <p>{deliveryDetails.address.organisation && deliveryDetails.address.organisation + ', '}</p>
          <p>{deliveryDetails.contactDetails?.contactName && deliveryDetails.contactDetails?.contactName + ', '}</p>
          <p>
            {deliveryDetails.address.streetLines && formatStreetLines(deliveryDetails.address.streetLines)}
            {deliveryDetails.address.city && deliveryDetails.address.city + ', '}
            {deliveryDetails.address.stateOrCounty && deliveryDetails.address.stateOrCounty + ', '}
            {deliveryDetails.address.countryCode && deliveryDetails.address.countryCode + ', '}
            {deliveryDetails.address.postalCode && deliveryDetails.address.postalCode}
          </p>
        </div>

        <div className="tracking-modal-content__downloads">
          <h3>Downloads</h3>

          <div className="tracking-modal-content__downloads__btn-container">
            {parcelNumbers?.length > 0 && <AMIButton
              variant='primary'
              isSpinning={fetchingLabels}
              onClick={onLabelsClick}
            >
              <Icon
                name="Download"
                color="white"
              />
              Label
            </AMIButton>}

            {parcelNumbers?.length < 1
            && <AMIButton
                variant='primary'
                disabled
                onClick={() => null}
              >
                <Icon
                  name="Download"
                  color="white"
                />
                Labels Unavailable
              </AMIButton>
            }

            {booking.carrierName === 'toll' &&
              <AMIButton
                variant="secondary"
                style={{padding: '12px'}}
                isSpinning={fetchingDocuments}
                onClick={onDocumentsClick}
              >
                <Icon
                  name="Download"
                  color="white"
                />
                End of Day Manifest
              </AMIButton>
            }
          </div>

          {!fetchingDocuments
            && documentDownloadFailed
            && booking.carrierName === 'toll'
            && (
              <p>Generated invoice is not ready please try again in 2 minutes</p>
            )
          }


          {!fetchingLabels && downloadFailed && <div>
            <p>Labels failed to download. Please
              <span onClick={onLabelsClick}> click here</span> to try again
            </p>
          </div>}

        </div>

        <div className="tracking-modal-content__tracking-info">
          <h3>Tracking</h3>
          {isFetchingTrackingInfo && (
            <AMISpinner
              style={{
                width: '50px',
                height: '50px'
              }}
            />
          )}

          {trackingEvents && trackingEvents.map((trackingEvent: any, index) => {
            return (
              <p key={index}>
                <strong>{dayjs(trackingEvent.zonedDateTime).format('DD MMM YYYY ')}
                | {dayjs(trackingEvent.zonedDateTime).format('HH:mm ')}</strong>
                - {trimIfString(trackingEvent.description)}
                {trackingEvent.signedBy ? `. Signed by ${trackingEvent.signedBy}` : ""}
              </p>
            )
          })}

          {!isFetchingTrackingInfo && (!trackingEvents || trackingEvents.length < 1) && (
            <p>We currently hold no tracking information for this booking</p>
          )}
        </div>
      </div>
    </AMIModal>
  )
}

export default TrackingModal;