import React, {useEffect, useState} from 'react';
import './DetailsPage.scss';
import AMISteps, { ActivePage } from '../../../steps/Steps';
import { AMIAlert, AMIButton, AMIPopConfirm } from '../../../ui-components/UiComponents';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { appDataActions, appDataSelector, AttachOrUpload, OwnOrGenerate, PageStatus } from '../../../features/appDataSlice';
import {
  bookingActions,
  bookingSelector, ImperialMetric,
  initialState,
  ShipmentType,
  TermsOfSale,
  TransportMethod
} from '../../../features/bookingSlice';
import ShipmentDetails from './shipment-details/ShipmentDetails';
import CollectionDetails from './collection-details/CollectionDetails';
import ConsigneeDetails from './consignee-details/ConsigneeDetails';
import DetailsPagePreferences from './preferences/DetailsPagePreferences';
import {useHistory} from 'react-router-dom';
import SummarySlider from './summary-slider/SummarySlider';
import {
  isBetweenXAndXCharacters,
  isLessThan10Characters,
  isValidEmail,
  isValidPostalCode,
  isValidShortAddressCode,
  isValidTelephone
} from '../../../utilities/ValidationUtilities';
import BrokerSelect from './broker-select/BrokerSelect';
import DropInDetails from './drop-in-details/DropInDetails';
import ShipperDetails from './shipper-details/ShipperDetails';
import CommoditiesCard from './commodities-card/CommoditiesCard';
import InvoiceCard from './invoice-card/InvoiceCard';
import { customerDetailsSelector } from "../../../features/customerSlice";
import {
  checkForTaxNumberErrors,
  checkInvoiceDate,
  checkInvoiceNumber,
  getCommoditiesVisible,
  populateShipperDetails
} from './utilities';
import {
  getRule,
  getTradeRoute,
  handleRules,
  isIntraEu,
  RuleSet,
  TradeRoute
} from "../../../utilities/RulesEngineUtilities";
import {getCarrierImage, getCarrierName, getOptionalChargeCost, OptionalCharge, scrollToElement, ServiceType} from '../../../utilities/HelperUtilities';
import InsuranceCard from './insurance-card/InsuranceCard';
import {retrieveCourierInfoContent, retrieveDDPContent} from "../../../utilities/ContentRetrieverUtilities";
import AMISpinner from "../../../ui-components/spinner/Spinner";
import {getCarrierTermsAndConditions, getCourierInformation} from "../../../utilities/CourierInfo";

const DetailsPage = () => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const isCollection = booking.selectedQuote.serviceType === 'COLLECTED';
  const appData = useAppSelector(appDataSelector);
  const brokerSelected = booking.brokerDetails.brokerSelected;
  const trueShipper = booking.trueShipper;
  const customer = useAppSelector(customerDetailsSelector);
  const isInternational = getTradeRoute(customer, booking) !== TradeRoute.DOMESTIC;
  const tradeRoute : TradeRoute | null = getTradeRoute(customer, booking);
  const apiConfig = appData.apiConfig;
  const bookingIsIntraEU :boolean = isIntraEu(booking);

  const history = useHistory();

  const [showStartOverPopConfirm, setShowStartOverPopConfirm] = useState(false);
  const [clearCollCountyState, setClearCollCountyState] = useState<boolean>(false);
  const [clearCneeShipperCountyState, setClearCneeShipperCountyState] = useState<boolean>(false);
  const [hideBroker, setHideBroker] = useState(false);

  useEffect(() => {
    handleRules(RuleSet.BOOKING, customer, booking, dispatch, apiConfig)
      .catch((error: any) => console.error(error.message));
    if (appData.detailsPageStatus !== PageStatus.COMPLETE) {
      window.scrollTo(0, 0);
    }
  }, [])

  useEffect(() => {
    setShipperTaxNumbers();
    setClearCollCountyState(getRule(appData.customRules, 'upsCollCountyStateHidden'));
    setClearCneeShipperCountyState(getRule(appData.customRules, 'upsCneeCountyStateHidden'));
    setHideBroker(getRule(appData.customRules, 'hideBroker'));
  }, [appData.customRules])

  // Should we use a template but select a ups shipment, the county state will cause the booking to fail
  // therefore we should clear these values if the field is (as per the customsRule) meant to be hidden entirely
  // and the shipment is not a collection type

  useEffect(() => {
    if (clearCollCountyState && !isCollection) {
      dispatch(bookingActions.updateCollectionDetail({
        field: 'countyStateProvince',
        value: ''
      }));
    }
  }, [clearCollCountyState])

  useEffect(() => {
    if (clearCneeShipperCountyState) {
      dispatch(bookingActions.updateConsigneeDetail({
        field: 'countyStateProvince',
        value: ''
      }));
      dispatch(bookingActions.updateShipperDetail({
        field: 'countyStateProvince',
        value: ''
      }));
    }
  }, [clearCneeShipperCountyState])

  const setShipperTaxNumbers = () => {
    const taxNumbers = booking.shipperDetails.taxNumbers;
    if (
      getRule(appData.customRules, 'shipperEori')
      && taxNumbers[0].type !== 'EORI'
    ) {
      dispatch(bookingActions.updateShipperTaxNumbers([{
        type: 'EORI',
        value: ''
      }]))
    }
  }

  const [collCountyStateRequired, setCollCountyStateRequired] = useState<boolean>(false);
  const [cneeCountyStateRequired, setCneeCountyStateRequired] = useState<boolean>(false);

  useEffect(() => {
    setCollCountyStateRequired(
      isCollection
      && getRule(appData.customRules, 'collCountyStateRequired')
      && getRule(appData.customRules, 'upsCollCountyStateHidden') === false
    );
    setCneeCountyStateRequired(
      appData.destinationCounties.length > 0
      && getRule(appData.customRules, 'upsCneeCountyStateHidden') === false
    );
  }, [appData.customRules])

  const [carrierTsAndCs, setCarrierTsAndCs] = useState<any>();

  useEffect(() => {
    setCarrierTsAndCs(getCarrierTermsAndConditions(booking, booking.selectedQuote.quoteId));
  }, [booking])

  const errHandler = {
    reasonForExport: {
      criteria: isInternational && !bookingIsIntraEU
        ? booking.reasonForExport
        : true,
      message: 'Required'
    },
    countryOfOrigin: {
      criteria: isInternational && !bookingIsIntraEU
        ? booking.countryOfOrigin
        : true,
      message: 'Required'
    },
    hawb: {
      criteria: booking.hawb
        && booking.hawb.trim().length >= 3
        && booking.hawb.trim().length <= 29,
      message: 'Between 3 and 29 characters'
    },
    contentDescription: {
      criteria: booking.contentDescription
        && booking.contentDescription.length > 2
        && booking.contentDescription.length < 41,
      message: 'Between 3 and 40 characters'
    },
    freightCharge: {
      criteria: booking.freightCharge && +booking.freightCharge !== 0 && !bookingIsIntraEU
        ? +booking.freightCharge > 0
        : true,
      message: 'Value must be greater than 0'
    },
    collectionDetails: {
      contactName: {
        criteria: isCollection
          ? isBetweenXAndXCharacters(booking.collectionDetails.contactName, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      companyName: {
        criteria: isCollection
          ? isBetweenXAndXCharacters(booking.collectionDetails.companyName, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      pickUpFrom: {
        criteria: isCollection
          ? booking.collectionDetails.pickUpFrom
          : true,
        message: 'Required'
      },
      pickUpTo: {
        criteria: isCollection
          ? booking.collectionDetails.pickUpTo
          : true,
        message: 'Required'
      },
      collectionTimes: {
        criteria: !appData.collectionTimesError
      },
      packageLocation: {
        criteria: isCollection
         ? isBetweenXAndXCharacters(booking.collectionDetails.packageLocation, 3, 35)
         : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine1: {
        criteria: isCollection
          ? isBetweenXAndXCharacters(booking.collectionDetails.addressLine1, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine2: {
        criteria: isCollection && booking.collectionDetails.addressLine2
          ? isBetweenXAndXCharacters(booking.collectionDetails.addressLine2, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine3: {
        criteria: isCollection && booking.collectionDetails.addressLine3
          ? isBetweenXAndXCharacters(booking.collectionDetails.addressLine3, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      countyStateProvince: {
        criteria: collCountyStateRequired && appData.shipperCounties.length > 0
          ? booking.collectionDetails.countyStateProvince
          : true,
        message: 'Required'
      },
      postalCode: {
        criteria: isCollection
        && appData.originPostalCodeRegex
          ? booking.originPostalCode
          : isLessThan10Characters(booking.originPostalCode),
        message: appData.originPostalCodeRegex
          ? 'Required. Max 10 characters including spaces'
          : 'Max 10 characters including spaces'
      },
      cityTown: {
        criteria: isCollection
          ? isBetweenXAndXCharacters(booking.originCityTown, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      telephoneNumber: {
        criteria: isCollection
          ? isValidTelephone(
              booking.collectionDetails.telephoneNumber,
              booking.origin.value === 'US'
            )
          : true,
        message: booking.origin.value === 'US'
          ? 'Must be 10 digits' : 'Must be between 10 and 15 digits'
      },
      email: {
        criteria: isCollection
          ? (booking.collectionDetails.email
            && isValidEmail(booking.collectionDetails.email))
          : true,
        message: 'Required. Must be a valid email.'
      },
      additionalInfo: {
        criteria: (isCollection && booking.collectionDetails.additionalInfo)
          ? booking.collectionDetails.additionalInfo.length <= 35
          : true,
        message: 'Must be less than 35 characters'
      }
    },
    shipperDetails: {
      contactName: {
        criteria: !trueShipper
        && (
          booking.selectedQuote.serviceType === 'DROP_IN'
          || isInternational
        )
          ? isBetweenXAndXCharacters(booking.shipperDetails.contactName, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      companyName: {
        criteria: !trueShipper
        && (
          booking.selectedQuote.serviceType === 'DROP_IN'
          || isInternational
        )
          ? isBetweenXAndXCharacters(booking.shipperDetails.companyName, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine1: {
        criteria: !trueShipper
        && (booking.selectedQuote.serviceType === 'DROP_IN' || isInternational)
          ? isBetweenXAndXCharacters(booking.shipperDetails.addressLine1, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine2: {
        criteria: (
          !trueShipper
          && (booking.selectedQuote.serviceType === 'DROP_IN' || isInternational)
          && booking.shipperDetails.addressLine2
        )
          ? isBetweenXAndXCharacters(booking.shipperDetails.addressLine2, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine3: {
        criteria: (
          !trueShipper
          && (booking.selectedQuote.serviceType === 'DROP_IN' || isInternational)
          && booking.shipperDetails.addressLine3
        )
          ? isBetweenXAndXCharacters(booking.shipperDetails.addressLine3, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      countyStateProvince: {
        criteria: !trueShipper
        && (
          booking.selectedQuote.serviceType === 'DROP_IN'
          || isInternational
        )
        && appData.shipperCounties.length > 0
          ? booking.shipperDetails.countyStateProvince
          : true,
        message: 'Required'
      },
      postalCode: {
        criteria: !trueShipper && (booking.selectedQuote.serviceType === 'DROP_IN' || isInternational)
          ? appData.shipperPostalCodeRegex
            ? booking.shipperDetails.postalCode
              && isValidPostalCode(booking.shipperDetails.postalCode, appData.shipperPostalCodeRegex)
            : isLessThan10Characters(booking.shipperDetails.postalCode)
          : true,
        message: appData.shipperPostalCodeRegex
          ? 'Required. Max 10 characters including spaces'
          : 'Max 10 characters including spaces'
      },
      country: {
        criteria: !trueShipper
        && (
          booking.selectedQuote.serviceType === 'DROP_IN'
          || isInternational
        )
          ? booking.shipperDetails.country && booking.shipperDetails.country.value
          : true,
        message: 'Required'
      },
      cityTown: {
        criteria: !trueShipper
        && (
          booking.selectedQuote.serviceType === 'DROP_IN'
          || isInternational
        )
          ? isBetweenXAndXCharacters(booking.shipperDetails.cityTown, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      telephoneNumber: {
        criteria: !trueShipper
        && (
          booking.selectedQuote.serviceType === 'DROP_IN'
          || isInternational
        )
          ? isValidTelephone(
              booking.shipperDetails.telephoneNumber,
              booking.shipperDetails.country.value === 'US'
            )
          : true,
        message: booking.shipperDetails.country.value === 'US'
          ? 'Must be 10 digits' : 'Must be between 10 and 15 digits'
      },
      email: {
        criteria: !trueShipper
        && (
          booking.selectedQuote.serviceType === 'DROP_IN'
          || isInternational
        )
          ? isValidEmail(booking.shipperDetails.email)
          : true,
        message: 'Required. Must be a valid email.'
      },
      taxNumbers: {
        criteria: getRule(appData.customRules, 'shipperEori')
          ? (
            booking.shipperDetails.taxNumbers[0].value
            && booking.shipperDetails.taxNumbers[0].type
          )
          : true,
        message: 'Required'
      },
      aes: {
        criteria: getRule(appData.customRules, 'aesItn')
          ? booking.shipperDetails.exportComplianceStatement.aes
          : true,
        message: 'Required'
      },
      edn: {
        criteria: getRule(appData.customRules, 'exportDeclarationNumber') && !booking.shipperDetails.generateECS
          ? booking.shipperDetails.exportComplianceStatement.edn
          : true,
        message: 'Required'
      },
      ccn: {
        criteria: getRule(appData.customRules, 'customerCustomsNumber') && !booking.shipperDetails.generateECS
          ? booking.shipperDetails.exportComplianceStatement.ccn
          : true,
        message: 'Required'
      },
      exporterCode: {
        criteria: (getRule(appData.customRules, 'southAfricaExportersCode') && appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE)
          ? +booking.shipperDetails.exportComplianceStatement.exporterCode?.length === 8
          : (getRule(appData.customRules, 'southAfricaExportersCode') && appData.invoice.ownOrGenerate === OwnOrGenerate.OWN_INVOICE && booking.shipperDetails.exportComplianceStatement.exporterCode)
            ? +booking.shipperDetails.exportComplianceStatement.exporterCode?.length === 8
            : true,
        message: (appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE)
          ? 'Required for us to generate your invoice online. Must be 8 digits'
          : 'Must be 8 digits'
      },
    },
    consigneeDetails: {
      contactName: {
        criteria: isBetweenXAndXCharacters(booking.consigneeDetails.contactName, 3, 35),
        message: 'Between 3 and 35 characters'
      },
      companyName: {
        criteria: isBetweenXAndXCharacters(booking.consigneeDetails.companyName, 3, 35),
        message: 'Between 3 and 35 characters'
      },
      addressLine1: {
        criteria:  isBetweenXAndXCharacters(booking.consigneeDetails.addressLine1, 3, 35),
        message: 'Between 3 and 35 characters'
      },
      addressLine2: {
        criteria: booking.consigneeDetails.addressLine2
          ? isBetweenXAndXCharacters(booking.consigneeDetails.addressLine2, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine3: {
        criteria: booking.consigneeDetails.addressLine3
          ? booking.destination.value === "SA"
                ? isValidShortAddressCode(booking.consigneeDetails.addressLine3)
            : isBetweenXAndXCharacters(booking.consigneeDetails.addressLine3, 3, 35)
          : true,
        message: booking.destination.value === "SA"
            ? 'Must be 4 letters and 4 numbers (e.g.ABCD1234)'
            : 'Between 3 and 35 characters'
      },
      countyStateProvince: {
        criteria: cneeCountyStateRequired
          ? booking.consigneeDetails.countyStateProvince
          : true,
        message: 'Required'
      },
      postalCode: {
        criteria: appData.destinationPostalCodeRegex
          ? booking.destinationPostalCode
          : isLessThan10Characters(booking.destinationPostalCode),
        message: appData.destinationPostalCodeRegex
          ? 'Required. Max 10 characters including spaces'
          : 'Max 10 characters including spaces'
      },
      cityTown: {
        criteria: isBetweenXAndXCharacters(booking.destinationCityTown, 3, 35),
        message: 'Between 3 and 35 characters'
      },
      telephoneNumber: {
        criteria: isValidTelephone(
          booking.consigneeDetails.telephoneNumber,
          booking.destination.value === 'US'
        ),
        message: booking.destination.value === 'US'
          ? 'Must be 10 digits' : 'Must be between 10 and 15 digits'
      },
      email: {
        criteria: booking.consigneeDetails.email
        && isValidEmail(booking.consigneeDetails.email),
        message: 'Required. Must be a valid email.'
      },
      additionalInfo: {
        criteria: booking.consigneeDetails.additionalInfo
          ? booking.consigneeDetails.additionalInfo.length >= 3 && booking.consigneeDetails.additionalInfo.length <= 25
          : true,
        message: 'Must be between 3 and 25 characters'
      }
    },
    brokerDetails: {
      contactName: {
        criteria: brokerSelected
          ? isBetweenXAndXCharacters(booking.brokerDetails.contactName, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      companyName: {
        criteria: brokerSelected
        ? isBetweenXAndXCharacters(booking.brokerDetails.companyName, 3, 35)
        : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine1: {
        criteria: brokerSelected
          ? isBetweenXAndXCharacters(booking.brokerDetails.addressLine1, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine2: {
        criteria: (brokerSelected && booking.brokerDetails.addressLine2)
          ? isBetweenXAndXCharacters(booking.brokerDetails.addressLine2, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      addressLine3: {
        criteria: booking.brokerDetails.addressLine3
          ? isBetweenXAndXCharacters(booking.brokerDetails.addressLine3, 3, 35)
          : true,
        message: 'Between 3 and 35 characters'
      },
      countyStateProvince: {
        criteria: brokerSelected
          && appData.brokerCounties.length > 0
            ? booking.brokerDetails.countyStateProvince
            : true,
        message: 'Required'
      },
      country: {
        criteria: brokerSelected
        ? booking.brokerDetails.country && booking.brokerDetails.country.value
        : true,
        message: 'Required'
      },
      postalCode: {
        criteria: brokerSelected
          ? appData.brokerPostalCodeRegex
            ? booking.brokerDetails.postalCode && isValidPostalCode(booking.brokerDetails.postalCode, appData.brokerPostalCodeRegex)
            : isLessThan10Characters(booking.brokerDetails.postalCode)
          : true,
        message: appData.brokerPostalCodeRegex
          ? 'Required. Max 10 characters including spaces'
          : 'Max 10 characters including spaces'
      },
      cityTown: {
        criteria: brokerSelected
        ? isBetweenXAndXCharacters(booking.brokerDetails.cityTown, 3, 35)
        : true,
        message: 'Between 3 and 35 characters'
      },
      telephoneNumber: {
        criteria: brokerSelected
        ? isValidTelephone(
          booking.brokerDetails.telephoneNumber,
          booking.brokerDetails.country.value === 'US'
        )
        : true,
        message: booking.brokerDetails.country.value === 'US'
          ? 'Must be 10 digits' : 'Must be between 10 and 15 digits'
      },
      email: {
        criteria: brokerSelected
          ? isValidEmail(booking.brokerDetails.email)
          : true,
        message: 'Required. Must be a valid email.'
      },
    },
    invoice: {
      number: {
        criteria: checkInvoiceNumber(booking, appData, isInternational),
        message: 'Required'
      },
      date: {
        criteria: checkInvoiceDate(booking, appData, isInternational),
        message: 'Date required'
      },
      name: {
        criteria: appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE
          ? isBetweenXAndXCharacters(appData.invoice.name, 1, 35)
          : true,
        message: 'Between 1 and 35 characters'
      },
      title: {
        criteria: appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE
          ? isBetweenXAndXCharacters(appData.invoice.position, 1, 35)
          : true,
        message: 'Between 1 and 35 characters'
      },
      uploadedInvoice: {
        criteria: appData.invoice.attachOrUpload === AttachOrUpload.UPLOAD
          ? appData.uploadedDocuments.length > 0
          : true,
        message: 'Please upload an invoice'
      },
      uploadedSignature: {
        criteria: appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE
          ? appData.uploadedDocuments.length > 0
          : true,
        message: 'Please upload a signature file'
      }
    },
    preferences: {
      email: {
        criteria: booking.preferences.email
        && isValidEmail(booking.preferences.email),
        message: 'Required. Must be a valid email.'
      },
      labelType: {
        criteria: booking.preferences.labelType,
        message: 'Required'
      }
    },
    phytosanitary: {
      phytosanitaryCheck: {
        criteria: booking.selectedQuote.transportMethod === TransportMethod.ROAD
        && getCarrierName(booking.selectedQuote.quoteId) === "dhl"
        && !getRule(appData.customRules, 'commodityExemption')
          ? booking.phytosanitaryCheck
          : true,
        message: 'Please confirm the above statement by checking the box.'
      }
    }
  }

  const onContinue = () => {
    dispatch(appDataActions.setShowDetailsPageErrors(true));

    for (const error in errHandler) {
      if ((errHandler as any)[error].message) {
        if (!(errHandler as any)[error].criteria) {
          dispatch(appDataActions.setDetailsPageErrors(true));
          return;
        }
      }

      if (!(errHandler as any)[error].message) {
        for (const subError in (errHandler as any)[error]) {
          if (!(errHandler as any)[error][subError].criteria) {
            dispatch(appDataActions.setDetailsPageErrors(true));
            return;
          }
        }
      }
    }

    const isCommodityOptional = appData.invoice.ownOrGenerate !== OwnOrGenerate.GENERATE
    && booking.shipmentType !== ShipmentType.NON_DOCS
    && getRule(appData.customRules, 'commodityExemption')

    if (
      appData.commodityWeightValueError
      && !isCommodityOptional
    ) {
      dispatch(appDataActions.setDetailsPageErrors(true));
      return;
    }

    const taxNumberErrors = checkForTaxNumberErrors(booking);

    if (
      appData.commodityPartialCompleteError
      || taxNumberErrors
    ) {
      dispatch(appDataActions.setDetailsPageErrors(true));
      return;
    }

    dispatch(appDataActions.setDetailsPageErrors(false));
    if (!appData.detailsPageCommoditiesHasErrors) {
      populateShipperDetails(booking, dispatch);
      history.push('/confirm');
      dispatch(appDataActions.setShowDetailsPageErrors(false));
      dispatch(appDataActions.setDetailsPageStatus(PageStatus.COMPLETE));
    }
  }

  const onStartOver = () => {
    setShowStartOverPopConfirm(true);
  }

  const popConfirmTarget = document.getElementById('details-page-start-over-btn');

  const cancelStartOver = () => {
    setShowStartOverPopConfirm(false);
  }

  const confirmStartOver = () => {
    setShowStartOverPopConfirm(false);
    dispatch(bookingActions.resetState());
    dispatch(appDataActions.resetStateWithConditions());
    history.push('/single-booking');
  }

  useEffect(() => {
    if (appData.sectionToEdit) {
      scrollToElement(appData.sectionToEdit);
      dispatch(appDataActions.setSectionToEdit(''));
    }
  }, [])

  useEffect(() => {
    if (appData.detailsPageStatus !== PageStatus.COMPLETE) {
      dispatch(appDataActions.setDetailsPageStatus(PageStatus.IN_PROGRESS));
    }
  }, [])

  const [courierInfoContent, setCourierInfoContent] = useState<any>("");
  const [isSpinning, setIsSpinning] = useState<boolean>(true);

  useEffect(() => {
    retrieveCourierInfoContent(booking, customer, apiConfig)
      .then((result: any) => {
          if (result.constructor !== Array) setCourierInfoContent(result)
          else setCourierInfoContent(getCourierInformation(customer, booking, booking.selectedQuote.quoteId, result).content)
          setIsSpinning(false)
        })
  }, [])

  const [furtherDDPInformation, setFurtherDDPInformation] = useState<any>("")

  useEffect(() => {
    retrieveDDPContent(booking, customer, apiConfig).then((result: any) => setFurtherDDPInformation(result));
  }, [courierInfoContent])

  useEffect(() => {
    if (booking.selectedQuote.serviceType === ServiceType.DROP_IN) {
      dispatch(bookingActions.updateField({
        field: 'trueShipper',
        value: false
      }))
    } else if (booking.selectedQuote.serviceType === ServiceType.COLLECTED) {
      if (booking.selectedQuote.transportMethod === TransportMethod.DOMESTIC) {
        dispatch(bookingActions.updateField({
          field: 'trueShipper',
          value: true
        }))
      } else {
        dispatch(bookingActions.updateField({
          field: 'trueShipper',
          value: booking.trueShipper
        }))
      }
    } else {
      dispatch(bookingActions.updateField({
        field: 'trueShipper',
        value: initialState.trueShipper
      }))
    }
  }, [])

  useEffect(() => {
    if (getRule(appData.customRules, 'commodityExemption')
      && getCarrierName(booking.selectedQuote.quoteId) === "dhl"
    ) {
      dispatch(bookingActions.setCommodities([{
        commodityCode: '_00000_',
        description: booking.contentDescription,
        weight: {
          value: booking.pieces[0].weight,
          unit: booking.imperialMetric === ImperialMetric.IMPERIAL
              ? 'LB'
              : 'KG'
        },
        quantity: {
          value: booking.pieces.length,
          unit: 'PCS'
        },
        unitPrice: {
          value: booking.totalShipmentValue
            ? booking.totalShipmentValue === '0'
              ? '0.01'
              : booking.totalShipmentValue
            : '0.01',
          unit: booking.preferredCurrency.value
        }
      }]));
    } else if (
      !getRule(appData.customRules, 'commodityExemption')
      && booking.customsDetails.commodities[0].commodityCode === '_00000_'
    ) {
      dispatch(bookingActions.setCommodities([]));
      dispatch(bookingActions.addCommodity(
          {
            commodityCode: '',
            description: '',
            weight: {
              value: '',
              unit: booking.imperialMetric === ImperialMetric.IMPERIAL
                  ? 'LB'
                  : 'KG'
            },
            quantity: {
              value: '',
              unit: 'PCS'
            },
            unitPrice: {
              value: '',
              unit: 'USD'
            }
          },
      ));
    }
  }, [appData.customRules, booking.contentDescription]);

  const [commoditiesVisible, setCommoditiesVisible] = useState<boolean>(false);

  useEffect(() => {
    getCommoditiesVisible(appData, setCommoditiesVisible);
  }, [appData.customRules]);

  return (
    <div className="details-page">
      <AMISteps activePage={ActivePage.DETAILS} />

      <div
        className="details-page__main-container default-page-grid"
        id="details-page__main-container"
      >

        <SummarySlider booking={booking} />

        <form className="details-page__main-container__details-container vertical-card">

          <ShipmentDetails errHandler={errHandler} apiConfig={apiConfig} />

          {getOptionalChargeCost(booking.selectedQuote, OptionalCharge.INSURANCE) !== 0
          && appData.displayInsurance
          && <InsuranceCard />}

          {
            booking.selectedQuote.serviceType === "DROP_IN"
            && customer.customerCountryCode !== "ZA"
            && <DropInDetails />
          }

          {booking.selectedQuote.serviceType === "COLLECTED" && <CollectionDetails errHandler={errHandler} customRules={appData.customRules}/>}

          {(
            booking.selectedQuote.serviceType === "DROP_IN"
            || tradeRoute !== TradeRoute.DOMESTIC
          ) && (
            <ShipperDetails
              errHandler={errHandler}
              customRules={appData.customRules}
            />
          )}

          <ConsigneeDetails
            errHandler={errHandler}
            customRules={appData.customRules}
          />

          {tradeRoute !== TradeRoute.DOMESTIC && !bookingIsIntraEU && !hideBroker && (
            <BrokerSelect
              errHandler={errHandler}
              customRules={appData.customRules}
            />
          )}

          {commoditiesVisible && (
              <CommoditiesCard
                customRules={appData.customRules}
                errHandler={errHandler}
              />
          )}

          {tradeRoute !== TradeRoute.DOMESTIC && !bookingIsIntraEU && (
            <InvoiceCard
              errHandler={errHandler}
              customRules={appData.customRules}
            />
          )}

          <DetailsPagePreferences errHandler={errHandler} />

        </form>

        {appData.showDetailsPageErrors
        && (appData.detailsPageHasErrors
        || appData.detailsPageCommoditiesHasErrors)
        && (
          <AMIAlert
            variant="error"
            className="details-page__error-message"
          >
            Please address all highlighted errors
          </AMIAlert>
        )}

        <div className="details-page__button-container">
          <AMIButton
            id="details-page-start-over-btn"
            onClick={onStartOver}
            variant="default"
            size="large"
          >Start Over</AMIButton>
          <AMIButton
            onClick={onContinue}
            variant="primary"
            size="large"
          >Continue</AMIButton>

          <AMIPopConfirm
            parentElement={popConfirmTarget}
            isVisible={showStartOverPopConfirm}
            position="centerScreen"
            onCancel={cancelStartOver}
            onConfirm={confirmStartOver}
          >
            <p>By starting over, all previously entered data will be cleared. Are you sure you want to start over?</p>
          </AMIPopConfirm>

        </div>

        <div className="details-page__main-container__courier-information-card vertical-card">
          <p className="details-container__title">Important Courier Information</p>
          <img
            src={getCarrierImage(booking.selectedQuote.quoteId)}
            alt="Logo"
          />
          {isSpinning && <AMISpinner className="faq-content-spinner"/>}
          {!isSpinning && furtherDDPInformation && booking.customsDetails.invoiceDetails.termsOfSale === TermsOfSale.DDP &&
              <div dangerouslySetInnerHTML={{__html: furtherDDPInformation}}></div>
          }
          {!isSpinning && (
            <div>
              <div dangerouslySetInnerHTML={{__html: courierInfoContent}}></div>
              {carrierTsAndCs && (
                <p>For more information, please check our
                  <Link
                    to={carrierTsAndCs}
                    rel="noreferrer"
                    target="_blank"
                    download
                  > terms and conditions</Link>
                </p>
              )}
            </div>
          )}
        </div>
      </div>

    </div>

  )
}

export default DetailsPage;