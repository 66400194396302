import React, { useEffect, useState } from 'react';
import './WlBookingModal.scss';
import {AMIButton, AMIModal, AMISpinner, Icon} from '../../../../../../ui-components/UiComponents';
import dayjs from 'dayjs';
import { WlBookingHistoryItem } from '../../WlBookingsPage-Utils';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getWlCustomerShipmentTracking } from '../../../../../../utilities/APIUtilities';
import { useAppSelector } from '../../../../../../app/hooks';
import { appDataSelector } from '../../../../../../features/appDataSlice';
import {
  downloadLabel,
  trimIfString
} from '../../../../../../utilities/HelperUtilities';
import {CustomerDetails} from "../../../../../../features/customerSlice";

const WlBookingModal: React.FC<{
  booking: WlBookingHistoryItem,
  closeModal: () => void,
  customer: CustomerDetails
}> = ({
  booking,
  closeModal,
  customer
}) => {

  const { apiConfig } = useAppSelector(appDataSelector);

  const [trackingEvents, setTrackingEvents] = useState([]);
  const [isFetchingTrackingInfo, setIsFetchingTrackingInfo] = useState(true);

  useEffect(() => {
    getTrackingInfo();
  }, [])

  const getTrackingInfo = async() => {
    const trackingInfo = await getWlCustomerShipmentTracking(booking, apiConfig);
    setIsFetchingTrackingInfo(false);
    if (trackingInfo) setTrackingEvents(trackingInfo.trackingEvents);
  }

  const [fetchingLabels, setFetchingLabels] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState(false);

  const onIndirectLabelsClick = async() => {
    setDownloadFailed(false);
    setFetchingLabels(true);
    const isSuccessful = await downloadLabel(apiConfig, booking, customer);
    if (!isSuccessful) setDownloadFailed(true);
    setFetchingLabels(false);
  }

  return (
    <AMIModal
      title={"Booking " + booking.customerShippingReference}
      width="80%"
      close={closeModal}
    >
      <div className="wl-booking-modal">
        <img
          src={`/images/${booking.carrierName}.png`}
          alt="Logo"
          className="wl-booking-modal__carrier-logo"
        />

        <div className="wl-booking-modal__booking-info">
          <p><span>Booking Date:</span><br/>{dayjs(booking.shipmentDate).format('DD/MM/YYYY')}</p>
          <p><span>Your Ref:</span><br/>{booking.customerShippingReference}</p>
          <p><span>AMI Ref:</span><br/>{booking.reference}</p>
          <p><span>Carrier Ref:</span><br/>{booking.carrierShippingReference}</p>
          <p><span>Company:</span><br/>{booking.companyName}</p>
        </div>

        <div className="wl-booking-modal__markup">
          <h3>Markup</h3>
          <div className="wl-booking-modal__markup__content">
            <div>
              <p>AMI Price</p>
              <p>Shipper Price</p>
              <p><strong>Difference</strong></p>
            </div>
            <div className="wl-booking-modal__markup__content__divide"></div>
            <div>
              <p>{getSymbolFromCurrency(booking.cost.currency)}{booking.cost.value}</p>
              <p>{getSymbolFromCurrency(booking.indirectCustomerCost.currency)}{booking.indirectCustomerCost.value}</p>
              <p><strong>{getSymbolFromCurrency(booking.markup.currency)}{booking.markup.value}</strong></p>
            </div>
          </div>

        </div>

        <div className="wl-booking-modal__downloads">
          <h3>Downloads</h3>

          <div className="tracking-modal-content__downloads__btn-container">
            {booking.carrierShippingReference
              && <AMIButton
                variant='primary'
                isSpinning={fetchingLabels}
                onClick={onIndirectLabelsClick}
              >
                <Icon
                  name="Download"
                  color="white"
                />
                Label
              </AMIButton>}

            {!booking.carrierShippingReference
              && <AMIButton
                variant='primary'
                disabled
                onClick={() => null}
              >
                <Icon
                  name="Download"
                  color="white"
                />
                Labels Unavailable
              </AMIButton>
            }
          </div>

          {!fetchingLabels && downloadFailed && <div>
            <p>Labels failed to download. Please
              <span onClick={onIndirectLabelsClick}> click here</span> to try again
            </p>
          </div>}

        </div>

        <div className="wl-booking-modal__tracking-info">
          <h3>Tracking</h3>
          {isFetchingTrackingInfo && (
            <AMISpinner
              style={{
                width: '50px',
                height: '50px'
              }}
            />
          )}

          {trackingEvents && trackingEvents.map((trackingEvent: any, index) => {
            return (
              <p key={index}>
                <strong>{dayjs(trackingEvent.zonedDateTime).format('DD MMM YYYY ')}
                  | {dayjs(trackingEvent.zonedDateTime).format('HH:mm ')}</strong>
                - {trimIfString(trackingEvent.description)}
                {trackingEvent.signedBy ? `. Signed by ${trackingEvent.signedBy}` : ""}
              </p>
            )
          })}

          {!isFetchingTrackingInfo && (!trackingEvents || trackingEvents.length < 1) && (
            <p>We currently hold no tracking information for this booking</p>
          )}
        </div>
      </div>
    </AMIModal>
  )
}

export default WlBookingModal;